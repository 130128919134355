import { useState, useEffect } from 'react';
import { Container, FloatingLabel, Form, Button } from 'react-bootstrap';
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { ChromePicker } from 'react-color';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'dayjs/locale/zh-tw'; // 引入 Day.js 的台北時區

dayjs.locale('zh-tw'); // 設置 Day.js 使用台北時區
const theme = createTheme({
    typography: {
        fontFamily: 'Fraunces, Arial, sans-serif', // 設置字型
    },
    components: {
        MuiTimePicker: {
            styleOverrides: {
                root: {
                    color: '#2C2351', // 設置文字顏色
                },
            },
        },
    },
});

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const StoreSetting = () => {
    const dispatch = useDispatch();
    const storeDB = useSelector(state => state.storeDB);

    const [fetchFromDB, setFetchFromDB] = useState(false);
    const [storeName, setStoreName] = useState('');
    const [serviceTimeInterval, setServiceTimeInterval] = useState('');
    const [openServiceTime, setOpenServiceTime] = useState('');
    const [closeServiceTime, setCloseServiceTime] = useState('');
    const [holiday, setHoliday] = useState('');

    const [channelAccessToken, setChannelAccessToken] = useState('');

    const [newStoreName, setNewStoreName] = useState('');

    const [mainColor, setMainColor] = useState('');
    const [subColor, setSubColor] = useState('');
    const [newMainColor, setNewMainColor] = useState(mainColor);
    const [newSubColor, setNewSubColor] = useState('');
    const [dialogColorPickerOpen, setDiologColorPickerOpen] = useState(false);

    const [openEditMainColor, setOpenEditMainColor] = useState(false);
    const [openEditSubColor, setOpenEditSubColor] = useState(false);
    const handleEditMainColorWindowOpen = () => setOpenEditMainColor(true);
    const handleEditSubColorWindowOpen = () => setOpenEditSubColor(true);
    const handleEditMainColorWindowClose = () => setOpenEditMainColor(false);
    const handleEditSubColorWindowClose = () => setOpenEditSubColor(false);

    const handleMainColorChange = (color) => setNewMainColor(color.hex);
    const handleSubColorChange = (color) => setNewSubColor(color.hex);

    useEffect(() => {
        setFetchFromDB(true);
        fetch(`${SERVER_BASE_URL}/api/get-store-setting/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                // dispatch({ type: 'SET_STORE_SETTING', payload: data[0] });
                setStoreName(data[0].storeName);
                setServiceTimeInterval(data[0].serviceTimeInterval);
                setOpenServiceTime(data[0].openServiceTime);
                setCloseServiceTime(data[0].closeServiceTime);
                setHoliday(data[0].holiday);
                setChannelAccessToken(data[0].channelAccessToken);

                setMainColor(data[0].mainColorStyle);
                setSubColor(data[0].subColorStyle);
            })
        setFetchFromDB(false);
    }, []);

    const handleEditMainColorConfirm = () => {
        setFetchFromDB(true);
        const accessToken = encodeURIComponent(channelAccessToken)
        fetch(`${SERVER_BASE_URL}/api/edit-store-setting/${storeDB}/${accessToken}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mainColorStyle: newMainColor
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'storeSetting', payload: data });
                setMainColor(newMainColor);
                toast.success(`前端主色調修改成功!`, {
                    duration: 3000
                });
            })
        handleEditMainColorWindowClose();
        setFetchFromDB(false);
    }

    const handleEditSubColorConfirm = () => {
        setFetchFromDB(true);
        fetch(`${SERVER_BASE_URL}/api/edit-store-setting/${storeDB}/${channelAccessToken}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subColorStyle: newSubColor
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'storeSetting', payload: data });
                setSubColor(newSubColor);
                toast.success(`前端副色調修改成功!`, {
                    duration: 3000
                });
            })
        handleEditSubColorWindowClose();
        setFetchFromDB(false);
    }

    const handleStoreNameChange = (event) => {
        setNewStoreName(event.target.value);
    }
    const handleServiceTimeIntervalChange = (event) => {
        setServiceTimeInterval(event.target.value);
    }
    const handleOpenServiceTimeChange = (value) => {
        const formattedOpenTime = dayjs(value).format('HH:mm');
        console.log(formattedOpenTime); // 在控制台中印出格式化後的時間
        setOpenServiceTime(formattedOpenTime);
    }
    const handleCloseServiceTimeChange = (value) => {
        const formattedCloseTime = dayjs(value).format('HH:mm');
        console.log(formattedCloseTime); // 在控制台中印出格式化後的時間
        setCloseServiceTime(formattedCloseTime);
    }
    const handleHolidayChange = (event) => {
        setHoliday(event.target.value);
    }

    const submitBtn = () => {
        if (dayjs(closeServiceTime, 'HH:mm').isBefore(dayjs(openServiceTime, 'HH:mm'))) {
            alert("您設定的「最晚開放預約時間」比「最早開放預約時間」還早");
            return;
        } else {
            const encodedChannelAccessToken = encodeURIComponent(channelAccessToken);
            // console.log(channelAccessToken);
            fetch(`${SERVER_BASE_URL}/api/edit-store-setting/${storeDB}/${encodedChannelAccessToken}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    storeName: newStoreName,
                    serviceTimeInterval: serviceTimeInterval,
                    openServiceTime: openServiceTime,
                    closeServiceTime: closeServiceTime,
                    holiday: holiday,
                })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);

                    setStoreName(data[0].storeName);
                    setServiceTimeInterval(data[0].serviceTimeInterval);
                    setOpenServiceTime(data[0].openServiceTime);
                    setCloseServiceTime(data[0].closeServiceTime);
                    setHoliday(data[0].holiday);

                    toast.success("更改成功", {
                        duration: 3000
                    })
                })
        }
    }

    return (
        <>
            {
                fetchFromDB ?
                    (
                        <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )
                    :
                    (
                        <>
                            <Container className='d-flex flex-column' style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", marginBottom: '20px' }}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="商家名稱"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={handleStoreNameChange} type="text" placeholder="您的品牌/商家名稱" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }} defaultValue={storeName} />
                                </FloatingLabel>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="前端畫面主色調"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="您的品牌主色調"
                                            style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                            value={mainColor}
                                            onChange={(e) => setNewMainColor(e.target.value)}
                                            onClick={handleEditMainColorWindowOpen}
                                        />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="前端畫面副色調"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="您的品牌副色調"
                                            style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                            value={subColor}
                                            onChange={(e) => setNewSubColor(e.target.value)}
                                            onClick={handleEditSubColorWindowOpen}
                                        />
                                    </FloatingLabel>
                                </div>

                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-tw">
                                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                <TimePicker
                                                    label="最早開放預約時間"
                                                    value={dayjs(openServiceTime, 'HH:mm')}
                                                    onChange={handleOpenServiceTimeChange}
                                                    sx={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                                />
                                                <TimePicker
                                                    label="最晚開放預約時間"
                                                    value={dayjs(closeServiceTime, 'HH:mm')}
                                                    onChange={handleCloseServiceTimeChange}
                                                    sx={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                                />
                                            </div>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </ThemeProvider>

                                <div style={{ marginTop: '20px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>預約開放時段間隔</span></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={serviceTimeInterval}
                                            label="takeTime"
                                            style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                            onChange={handleServiceTimeIntervalChange}
                                        >
                                            <MenuItem value={30}>30 分鐘</MenuItem>
                                            <MenuItem value={60}>60 分鐘</MenuItem>
                                            <MenuItem value={90}>90 分鐘</MenuItem>
                                            <MenuItem value={120}>120 分鐘</MenuItem>
                                            <MenuItem value={150}>150 分鐘</MenuItem>
                                            <MenuItem value={180}>180 分鐘</MenuItem>
                                            <MenuItem value={210}>210 分鐘</MenuItem>
                                            <MenuItem value={240}>240 分鐘</MenuItem>
                                            <MenuItem value={270}>270 分鐘</MenuItem>
                                            <MenuItem value={300}>300 分鐘</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Form.Text id="HelpBlock" muted>
                                        <i style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>每次服務要間隔多久才能再接下一位客人。假設您選擇30分鐘，若當今天 13:30 有客戶預約 A 服務，則 A 服務下一個開放預約時段則是14:00。</i>
                                    </Form.Text>
                                </div>

                                <div style={{ marginTop: '20px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>固定休息日</span></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={holiday}
                                            label="takeTime"
                                            style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                            onChange={handleHolidayChange}
                                        >
                                            <MenuItem value={"Weekend"}>週休二日(六日)</MenuItem>
                                            <MenuItem value={"Mon"}>固定休每週一</MenuItem>
                                            <MenuItem value={"Tue"}>固定休每週二</MenuItem>
                                            <MenuItem value={"Wed"}>固定休每週三</MenuItem>
                                            <MenuItem value={"Thr"}>固定休每週四</MenuItem>
                                            <MenuItem value={"Fri"}>固定休每週五</MenuItem>
                                            <MenuItem value={"Sat"}>固定休每週六</MenuItem>
                                            <MenuItem value={"Sun"}>固定休每週日</MenuItem>
                                            <MenuItem value={"Manual"}>其他</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Form.Text id="HelpBlock" muted>
                                        <i style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>休息日將不開放任何預約服務。若您希望每天都能接受預約或是無特定休息日，請選擇「其他」並至手動設定自行定義可預約時段。</i>
                                    </Form.Text>
                                </div>

                                <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                                    <Button className="py-2 rounded-pill" variant="primary" onClick={submitBtn} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>儲存設定</span></Button>
                                </Container>
                            </Container>

                            {/* 「更改主色調顏色」彈跳視窗 */}
                            <Dialog open={openEditMainColor} onClose={handleEditMainColorWindowClose}>
                                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>更改前端主色調</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        margin="dense"
                                        id="self-color"
                                        label="主色調"
                                        type="text"
                                        fullWidth
                                        name="self-color"
                                        value={newMainColor}
                                        onChange={(e) => setNewMainColor(e.target.value)}
                                        onClick={() => setDiologColorPickerOpen(!dialogColorPickerOpen)}
                                    />
                                    {dialogColorPickerOpen && (
                                        <ChromePicker
                                            color={newMainColor}
                                            onChange={handleMainColorChange}
                                        />
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleEditMainColorWindowClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                                    <Button onClick={handleEditMainColorConfirm} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定修改</Button>
                                </DialogActions>
                            </Dialog>


                            {/* 「更改副色調顏色」彈跳視窗 */}
                            <Dialog open={openEditSubColor} onClose={handleEditSubColorWindowClose}>
                                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>更改前端副色調</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        margin="dense"
                                        id="self-color"
                                        label="副色調"
                                        type="text"
                                        fullWidth
                                        name="self-color"
                                        value={newSubColor}
                                        onChange={(e) => setNewSubColor(e.target.value)}
                                        onClick={() => setDiologColorPickerOpen(!dialogColorPickerOpen)}
                                    />
                                    {dialogColorPickerOpen && (
                                        <ChromePicker
                                            color={newSubColor}
                                            onChange={handleSubColorChange}
                                        />
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleEditSubColorWindowClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                                    <Button onClick={handleEditSubColorConfirm} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定修改</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
            }
        </>
    );
}

export default StoreSetting;
import { Container, Card, Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoReturnUpBack } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const SelfAppointmentHistoryDetail = () => {
    const storeDB = useSelector(state => state.storeDB);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const rowData = location.state.navbar;
    const [fetchFromDB, setFetchFromDB] = useState(false);
    const [showToDoneDialog, setShowToDoneDialog] = useState(false);
    const [showToNoShowDialog, setShowToNoShowDialog] = useState(false);
    const [showToCancelDialog, setShowToCancelDialog] = useState(false);

    console.log(rowData);

    const closeToDoneDialog = () => {
        setShowToDoneDialog(false);
    }

    const closeToNoShowDialog = () => {
        setShowToNoShowDialog(false);
    }

    const closeToCancelDialog = () => {
        setShowToCancelDialog(false);
    }

    const pendingToDone = () => {
        setShowToDoneDialog(true);
    }

    const pendingToNoShow = () => {
        setShowToNoShowDialog(true);
    }

    const pendingToCancel = () => {
        setShowToCancelDialog(true);
    }

    const confirmToChangeState = (new_status) => {
        setFetchFromDB(true);
        fetch(`${SERVER_BASE_URL}/api/edit-appoinment-histort-status/${storeDB}/${rowData.id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                status: new_status,
                /* Status狀態 
                pending: 代辦
                done: 正常完成
                noshow: 放鳥
                cancel: 客人取消預約
                */
            })
        })
            .then(res => res.json())
            .then(data => {
                rowData.status = new_status;
                dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: data });
                toast.success("狀態更改成功", {
                    duration: 3000
                });
                setShowToDoneDialog(false);
                setShowToNoShowDialog(false);
                setShowToCancelDialog(false);
            })
        setFetchFromDB(false);
    }


    return (
        <>
            {
                fetchFromDB &&
                (
                    <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
            }

            <Container className='d-flex flex-column' style={{ marginTop: "5px", marginLeft: "5px", marginBottom: '20px' }}>
                <IoReturnUpBack size={30} onClick={() => { navigate(-1) }} style={{ color: '#2C2351', marginTop: '30px', marginLeft: '30px', cursor: 'pointer' }} />
            </Container>

            <Card style={{ width: '60vw', marginLeft: '60px' }}>
                <Card.Body>
                    <Card.Title><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>編號: #{rowData.id}</span></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '14px' }}>{rowData.currentTime}</span></Card.Subtitle>
                    <Card.Text>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>預約人: {rowData.displayName}</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>預約項目: {rowData.title}</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>服務時間: {rowData.bookDate} {rowData.bookTime}</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>服務預計金額: ${rowData.price} 起</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>服務預計所花時間: {rowData.takeTime} mins</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>此服務備註: <div style={{ whiteSpace: 'pre-wrap' }}>{rowData.remark === "" ? "-" : rowData.remark}</div></li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>狀態: {rowData.status === "" ? "-" : rowData.status}</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>給店家之備註: <div style={{ whiteSpace: 'pre-wrap' }}>{rowData.commentToStore}</div></li></span>
                    </Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button variant="primary" onClick={pendingToDone} disabled={rowData.status === "cancel" || rowData.status === "done" || rowData.status === "noshow"} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>
                            <span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                此筆預約完成 ✅
                            </span>
                        </Button>
                        <Button variant="primary" onClick={pendingToNoShow} disabled={rowData.status === "cancel" || rowData.status === "done" || rowData.status === "noshow"} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>
                            <span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                No Show 🐦
                            </span>
                        </Button>
                        <Button variant="primary" onClick={pendingToCancel} disabled={rowData.status === "cancel" || rowData.status === "done" || rowData.status === "noshow"} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>
                            <span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                取消此筆預約
                            </span>
                        </Button>
                    </div>
                </Card.Body>
            </Card>


            {/* 更改預約狀態成 DONE */}
            <Modal show={showToDoneDialog} onHide={closeToDoneDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>更改預約狀態</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: '#2C2351' }}>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定將此預約狀態改為完成嗎</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeToDoneDialog} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: '#2C2351', backgroundColor: 'transparent', color: '#2C2351' }}>
                        返回
                    </Button>
                    <Button variant="primary" onClick={() => { confirmToChangeState("done"); }} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* 更改預約狀態成 NOSHOW */}
            <Modal show={showToNoShowDialog} onHide={closeToNoShowDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>更改預約狀態</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: '#2C2351' }}>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定將此預約狀態改為No Show嗎</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeToNoShowDialog} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: '#2C2351', backgroundColor: 'transparent', color: '#2C2351' }}>
                        返回
                    </Button>
                    <Button variant="primary" onClick={() => { confirmToChangeState("noshow"); }} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* 更改預約狀態成 Cancel */}
            <Modal show={showToCancelDialog} onHide={closeToCancelDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>更改預約狀態</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: '#2C2351' }}>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定將此預約狀態改為 cancel 嗎</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeToCancelDialog} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: '#2C2351', backgroundColor: 'transparent', color: '#2C2351' }}>
                        返回
                    </Button>
                    <Button variant="primary" onClick={() => { confirmToChangeState("cancel"); }} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SelfAppointmentHistoryDetail;
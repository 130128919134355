// store.js
import { configureStore } from '@reduxjs/toolkit';

// 初始狀態
const initialState = {
    storeDB: '',
    storeSetting: [],
    userInfo: [],
    pointMethodList: [],
    appointmentList: [],
    appointmentHistory: [],
    couponList: [],
    couponHistory: [],
    tagList: [],
    openCloseDateTime: [],

    storePartners: []
};

// 定義 reducer 函數
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_STORE_DB':
            return { ...state, storeDB: action.payload };

        case 'SET_OPEN_CLOSE_DATE_TIME':
            return { ...state, openCloseDateTime: action.payload };

        case 'SET_STORE_SETTING':
            return { ...state, storeSetting: action.payload };

        case 'SET_USER_INFO':
            return { ...state, userInfo: action.payload };

        case 'SET_POINT_METHODS':
            return { ...state, pointMethodList: action.payload };

        case 'SET_APPOINTMENT_LISTS':
            return { ...state, appointmentList: action.payload };

        case 'SET_APPOINTMENT_HISTORY':
            return { ...state, appointmentHistory: action.payload };

        case 'SET_COUPON_LIST':
            return { ...state, couponList: action.payload };

        case 'SET_COUPON_HISTORY':
            return { ...state, couponHistory: action.payload };

        case 'SET_TAG_LIST':
            return { ...state, tagList: action.payload };

        case 'SET_STORE_PARTNERS':
            return { ...state, storePartners: action.payload };

        default:
            return state;
    }
};

// 建立 Redux store
const store = configureStore({ reducer });

export default store;

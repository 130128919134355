import { useNavigate, useLocation } from "react-router-dom";
import { IoReturnUpBack } from "react-icons/io5";
import { Container, Row, Col, Image, Nav, Button } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, TextareaAutosize } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from 'react-bootstrap/Spinner';
import Chip from '@mui/material/Chip';
import SelfAppointmentHistory from "../Components/SelfAppointmentHistory";
import SelfCouponHistory from "../Components/SelfCouponHistory";
import toast from 'react-hot-toast';
import flexModel from '../Components/FlexModel.json';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;


const ClientDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const uid = userId;
    // const [rowData, setRowData] = useState(location.state.rowData);
    // const [userData, setUserData] = useState([]);


    const storeDB = useSelector(state => state.storeDB);
    const userInfo = useSelector(state => state.userInfo);
    const user = userInfo.find(user => user.userId === userId);
    const name = user.displayName;
    const picture = user.pictureUrl;
    const phone = user.phone;
    const [points, setPoints] = useState(user.points);
    const [tag, setTag] = useState(user.tag);

    const [activeKey, setActiveKey] = useState("self-appointment-history"); //預設先顯示「預約歷史」tab
    // 換 tab
    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    const [assignPointDialog, setAssignPointDialog] = useState(false);
    const [pushMessageDialog, setPushMessageDialog] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const [newPoints, setNewPoints] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const tagList = useSelector(state => state.tagList);

    const [channelAccessToken, setChannelAccessToken] = useState("");

    useEffect(() => {
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/get-store-setting/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                // dispatch({ type: 'SET_STORE_SETTING', payload: data[0] });
                setChannelAccessToken(data[0].channelAccessToken)
            })
        setIsLoading(false);
    }, []);

    // 避免重新整理此頁
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            // Custom logic to handle the refresh
            // Display a confirmation message or perform necessary actions
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const closeAssignPointDialog = () => {
        setAssignPointDialog(false);
    }

    const closePushMessageDialog = () => {
        setPushMessageDialog(false);
    }

    const openAssignPointDialog = () => {
        setAssignPointDialog(true);
    }

    const openPushMessageDialog = () => {
        setPushMessageDialog(true);
    }

    const generateJson = (params) => {
        // 將模板中的佔位符替換為參數值
        const jsonString = JSON.stringify(flexModel)
            .replace('{{emoji}}', params.emoji)
            .replace('{{eventTitle}}', params.eventTitle)
            .replace('{{currentTime}}', params.currentTime)
            .replace('{{eventDescription}}', params.eventDescription)
            .replace('{{eventPrice}}', params.eventPrice)
        // .replace('{{eventTakeTime}}', params.eventTakeTime)
        // .replace('{{eventRemark}}', params.eventRemark)
        // .replace('{{eventCommentToStore}}', params.eventCommentToStore);

        return JSON.parse(jsonString);
    };

    const confirmAssignPoint = () => {
        const new_points = parseInt(points) + parseInt(newPoints);
        if (isNaN(new_points)) {
            alert("請輸入數字")
        }
        else {
            setIsLoading(true);
            fetch(`${SERVER_BASE_URL}/api/edit-member-info/${storeDB}/${uid}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    points: new_points,
                })
            })
                .then(res => res.json())
                .then(data => {
                    dispatch({ type: 'SET_USER_INFO', payload: data });
                    setPoints(new_points);

                    // 目前時間
                    const now = new Date();
                    const year = now.getFullYear();
                    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要 +1
                    const day = now.getDate().toString().padStart(2, '0');
                    const hours = now.getHours().toString().padStart(2, '0');
                    const minutes = now.getMinutes().toString().padStart(2, '0');
                    const seconds = now.getSeconds().toString().padStart(2, '0');
                    const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
                    // End

                    const params = {
                        emoji: '🙌',
                        eventTitle: `收到 ${parseInt(newPoints)} 點`,
                        currentTime: `於 ${formattedTime} 獲得`,
                        eventDescription: `恭喜您已成功獲得 ${parseInt(newPoints)}點`,
                        eventPrice: `快到會員系統確認，並至「優惠兌換」頁面查看/兌換最新的優惠!`,
                        // eventTakeTime: `服務時間大約 ${takeTime}分鐘`,
                        // eventRemark: `此服務注意事項: ${remark}`,
                        // eventCommentToStore: `給店家的備註: ${comment}`
                    };
                    const pushFlex = generateJson(params);

                    const encodedChannelAccessToken = encodeURIComponent(channelAccessToken);
                    const encodedUserId = encodeURIComponent(uid);
                    fetch(`${SERVER_BASE_URL}/api/push-line-flex-message/${encodedChannelAccessToken}/${encodedUserId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            messages: pushFlex,
                        }),
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log('Message sent:', data);
                        })
                        .catch(error => {
                            console.error('Error sending message:', error);
                        });

                    toast.success("點數更改成功", {
                        duration: 5000
                    });
                    closeAssignPointDialog();
                    setIsLoading(false);
                    // window.location.reload();
                })
        }
    }

    // 使 tags 具有id
    useEffect(() => {
        // 将tag数组转换为指定形式的数组，并使用setTags更新state
        const updatedTags = tag.map(item => {
            const foundTag = tagList.find(tagItem => tagItem.title === item);
            if (foundTag) {
                return {
                    description: foundTag.description,
                    title: foundTag.title,
                    id: foundTag.id,
                    _id: foundTag._id
                };
            } else {
                return null; // 处理找不到匹配项的情况
            }
        }).filter(tag => tag !== null); // 过滤掉空标签

        setTags(updatedTags);
    }, [tag, tagList]); // 添加 tag 和 tagList 依赖


    useEffect(() => {
        setIsLoading(true);
        let idsArray = [];
        if (tags.length === 0) { };
        for (let i = 0; i < tags.length; i++) {
            idsArray = tags.map(tag => tag.id);
        }
        fetch(`${SERVER_BASE_URL}/api/edit-member-info/${storeDB}/${uid}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                tag: idsArray
            })
        })
            .then(res => res.json())
            .then(data => {
                fetch(`${SERVER_BASE_URL}/api/get-all-user-info/${storeDB}`)
                    .then(response => response.json())
                    .then(userInfo => {
                        dispatch({ type: 'SET_USER_INFO', payload: userInfo });
                        setIsLoading(false);
                    })
            })
    }, [tags]);

    const handleTagsChange = (event, newValue) => {
        setTags(newValue);
    };

    const pushMessage = () => {
        // console.log(messageContent);
        const encodedChannelAccessToken = encodeURIComponent(channelAccessToken);
        fetch(`${SERVER_BASE_URL}/api/push-line-message/${encodedChannelAccessToken}/${uid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages: messageContent,
            }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Message sent:', data);
                setPushMessageDialog(false);
                toast.success("訊息已成功傳送", {
                    duration: 5000,
                });
            })
            .catch(error => {
                console.error('Error sending message:', error);
                // 可以在这里处理发送失败后的逻辑
            });
    };


    const backToPrevious = () => {
        navigate(-1, {
            state: { refresh: true },
        });
    }

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            <Container className='d-flex flex-column' style={{ marginTop: "5px", marginLeft: "5px", marginBottom: '20px' }}>
                <IoReturnUpBack size={30} onClick={() => { backToPrevious(); }} style={{ color: '#2C2351', marginTop: '30px', marginLeft: '30px', cursor: 'pointer' }} />
            </Container>

            <Container style={{ marginLeft: '60px', marginTop: '30px', width: '80%' }}>
                <Row className="mt-3">
                    <Col xs={2} style={{ paddingRight: '0' }}>
                        <Image src={picture} alt="Avatar" roundedCircle fluid style={{ width: '80%', height: 'auto' }} />
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <Row>
                            <Col>
                                <div style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><b>客戶名稱:</b> {name}</div>
                                <br />
                                <div style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><b>聯絡電話:</b> {phone}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <Row>
                            <Col>
                                <div style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><b>點數:</b> {points}點</div>
                                <br />
                                <div style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><b>UID:</b> {uid}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Button onClick={openPushMessageDialog} style={{ border: 'none', backgroundColor: 'rgba(44, 35, 81, 0.8)' }}><span style={{ color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>主動傳送訊息</span></Button>
                <Button onClick={openAssignPointDialog} style={{ marginLeft: '10px', border: 'none', backgroundColor: 'rgba(44, 35, 81, 0.8)' }}><span style={{ color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>發放點數</span></Button>
            </Container>

            <Container style={{ marginLeft: '60px', marginTop: '30px' }}>
                <Autocomplete
                    multiple
                    limitTags={5}
                    id="multiple-limit-tags"
                    options={tagList.filter(option => !tags.some(tag => tag.title === option.title))}
                    getOptionLabel={(option) => option.title}
                    value={tags}
                    onChange={handleTagsChange}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={index}
                                label={option.title}
                                {...getTagProps({ index })}
                                style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', color: 'white', fontWeight: 'bold', marginRight: 5 }}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="用戶標籤" placeholder="點這新增標籤" />
                    )}
                    sx={{ width: '60vw' }}
                />
            </Container>

            <Container style={{ marginLeft: '60px', marginTop: '30px' }}>
                <Nav variant="underline" defaultActiveKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="self-appointment-history"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>預約歷史</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="self-coupon-history"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>優惠兌換歷史</span></Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeKey === "self-appointment-history" && <SelfAppointmentHistory userId={uid} />}
                {activeKey === "self-coupon-history" && <SelfCouponHistory userId={uid} />}
            </Container>

            {/* 「發放點數」彈跳視窗 */}
            <Dialog open={assignPointDialog} onClose={closeAssignPointDialog}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>發放點數</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="points"
                        label="要發放幾點?"
                        type="number"
                        fullWidth
                        name="points"
                        // value={editedTagName}
                        onChange={(e) => setNewPoints(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAssignPointDialog} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={confirmAssignPoint} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定發放</Button>
                </DialogActions>
            </Dialog>

            {/* 「主動傳送訊息」彈跳視窗 */}
            <Dialog open={pushMessageDialog} onClose={closePushMessageDialog}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>主動推播訊息</DialogTitle>
                <DialogContent>
                    <TextareaAutosize
                        autoFocus
                        id="message"
                        minRows={4} // 设置 TextArea 的最小行数
                        maxRows={8} // 设置 TextArea 的最大行数
                        // value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        placeholder="請輸入訊息"
                        style={{ width: '100%', resize: 'none' }} // 调整 TextArea 的宽度并禁用 resize
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePushMessageDialog} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={pushMessage} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定發放</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default ClientDetail;
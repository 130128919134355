import { useState } from 'react';
import { Container, FloatingLabel, Form, Button } from 'react-bootstrap';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const ChangeAccPass = () => {
    const storeDB = useSelector(state => state.storeDB);

    const [fetchFromDB, setFetchFromDB] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [account, setAccount] = useState("");
    const [password, setPassword] = useState("");

    const [newAccount, setNewAccount] = useState("");
    const [newOnePassword, setNewOnePassword] = useState("");
    const [newTwoPassword, setNewTwoPassword] = useState("");
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);

    const editAccPass = () => {
        if (!isPasswordMatch) {
            alert("兩次密碼輸入不相符");
            return;
        }
        setFetchFromDB(true);

        console.log("Sending request to backend with account:", newAccount, "password:", newTwoPassword);

        fetch(`${SERVER_BASE_URL}/api/edit-backend-login-info/${storeDB}/true`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: newAccount,
                password: newTwoPassword,
            })
        })
            .then(res => {
                if (!res.ok) {
                    return res.json().then(error => {
                        throw new Error(error.error);
                    });
                }
                return res.json();
            })
            .then(data => {
                setNewOnePassword("");
                setNewTwoPassword("");
                toast.success("更改成功", {
                    duration: 3000,
                });
                // 成功登录处理
            })
            .catch(err => {
                alert(err.message);
            });
        setFetchFromDB(false);
    }

    const handleNewTwoPasswordChange = (e) => {
        const value = e.target.value;
        setNewTwoPassword(value);
        setIsPasswordMatch(value === newOnePassword);
    };

    const loginBtn = () => {
        fetch(`${SERVER_BASE_URL}/api/backend-login/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "account": account,
                "password": password,
            })
        })
            .then(res => {
                if (!res.ok) {
                    return res.json().then(error => {
                        throw new Error(error.error);
                    });
                }
                return res.json();
            })
            .then(data => {
                setNewAccount(data.account);
                setIsLoggedIn(true);
                // 成功登录处理
            })
            .catch(err => {
                alert(err.message);
            });
    }

    return (
        <>
            {
                fetchFromDB ?
                    (
                        <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )
                    :
                    (
                        <>
                            {
                                isLoggedIn ?
                                    (
                                        <>
                                            <Container className='d-flex flex-column' style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", marginBottom: '20px' }}>
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="您的帳號 (若只改密碼，請勿修改此欄位)"
                                                    className="mb-3"
                                                >
                                                    <Form.Control type="text" placeholder="您的帳號" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }} value={newAccount} onChange={(e) => setNewAccount(e.target.value)} />
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        <i style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                                            「只改密碼，不改帳號」: 本欄位請勿動
                                                            <br />
                                                            「只改帳號，不改密碼」: 更改本欄，且重新輸入兩次原始密碼
                                                            <br />
                                                            「改帳號、也改密碼」: 每一欄都須填寫
                                                        </i>
                                                    </Form.Text>
                                                </FloatingLabel>

                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="新密碼"
                                                    className="mb-3"
                                                >
                                                    <Form.Control type="password" placeholder="您的密碼" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }} onChange={(e) => setNewOnePassword(e.target.value)} />
                                                </FloatingLabel>

                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="再次輸入新密碼"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        required
                                                        isValid={newTwoPassword && isPasswordMatch}
                                                        isInvalid={newTwoPassword && !isPasswordMatch}
                                                        type="password"
                                                        placeholder="您的密碼"
                                                        style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                                        onChange={handleNewTwoPasswordChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        密碼與第一次輸入不相符
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="valid">
                                                        密碼匹配
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>

                                                <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                                                    <Button onClick={editAccPass} className="py-2 rounded-pill" variant="primary" style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定修改</span></Button>
                                                </Container>
                                            </Container >
                                        </>
                                    )
                                    :
                                    (
                                        <Dialog open={!isLoggedIn} >
                                            <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>請先登入</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    id="acc"
                                                    label="帳號"
                                                    type="text"
                                                    fullWidth
                                                    name="title"
                                                    // value={editedTagName}
                                                    onChange={(e) => setAccount(e.target.value)}
                                                />
                                                <TextField
                                                    margin="dense"
                                                    id="pass"
                                                    label="密碼"
                                                    type="password"
                                                    fullWidth
                                                    name="description"
                                                    // value={editedTagDescription}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={loginBtn} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>登入</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )
                            }
                        </>
                    )
            }
        </>
    );
}

export default ChangeAccPass;
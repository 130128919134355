import { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const BroadcastMessage = () => {
    const storeDB = useSelector(state => state.storeDB);
    const tagList = useSelector(state => state.tagList);
    const [tags, setTags] = useState([]);
    const [accordingToTag, setAccordingToTag] = useState(false);
    const [allTags, setAllTags] = useState(true);
    const [textareaInput, setTextareaInput] = useState("");
    const [openDiolog, setOpenDiolog] = useState(false);
    const [reachPeopleCount, setReachPeopleCount] = useState('');
    const [receiveUsersId, setReceiveUsersId] = useState([]);

    const [fetchFromDB, setFetchFromDB] = useState(false);

    const userInfo = useSelector(state => state.userInfo);
    const [channelAccessToken, setChannelAccessToken] = useState("");

    useEffect(() => {
        fetch(`${SERVER_BASE_URL}/api/get-store-setting/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                // dispatch({ type: 'SET_STORE_SETTING', payload: data[0] });
                setChannelAccessToken(data[0].channelAccessToken)
            })
    }, []);

    const handleDiologClose = () => {
        setReachPeopleCount(0);
        setReceiveUsersId([]);
        setOpenDiolog(false);
    }

    const handleTagsChange = (event, newValue) => {
        setTags(newValue);
    };

    const handleTagRadioChange = (event) => {
        if (event.target.value === 'allTagMember') {
            setAllTags(true);
        }
        else if (event.target.value === 'IncludeTagMember') {
            setAllTags(false);
        }
    }

    const handleReceiverChange = (event) => {
        if (event.target.value === 'allMember') {
            setAccordingToTag(false);
        }
        else if (event.target.value === 'tagMember') {
            setAccordingToTag(true);
        }
    };

    const textareaInputChange = (event) => {
        setTextareaInput(event.target.value);
    }

    const submitBroadcast = () => {
        if (!accordingToTag) { // 發送給所有人
            for (const user of userInfo) {
                setReceiveUsersId(prevReceiveUsersId => [...prevReceiveUsersId, user.userId]);
            }
            setReachPeopleCount(userInfo.length);
        }
        else { // 根據標籤發送
            if (tags.length === 0) {
                alert("未選取標籤");
                return;
            }
            else {
                let counter = 0;
                if (allTags) { // 該 user 的 tag array 需要包含「全部」所選標籤                    
                    userInfo.forEach(user => {
                        const hasAllTags = tags.every(tag => user.tag.includes(tag.title));
                        if (hasAllTags) {
                            counter += 1;
                            setReceiveUsersId(prevReceiveUsersId => [...prevReceiveUsersId, user.userId]);
                        }
                    });
                } else { // 該 user 的 tag array 需要包含「至少一個」所選標籤
                    userInfo.forEach(user => {
                        const hasAnyTag = tags.some(tag => user.tag.includes(tag.title));
                        if (hasAnyTag) {
                            counter += 1;
                            setReceiveUsersId(prevReceiveUsersId => [...prevReceiveUsersId, user.userId]);
                        }
                    });
                }

                if (counter === 0) {
                    alert('無符合的用戶');
                    return;
                } else {
                    setReachPeopleCount(counter);
                }
            }
        }
        setOpenDiolog(true);
    }

    const finalSubmit = () => {
        if (textareaInput === "") {
            alert("沒有輸入訊息");
        } else {
            setFetchFromDB(true);
            for (const user_id of receiveUsersId) {
                console.log(user_id);
                const encodedChannelAccessToken = encodeURIComponent(channelAccessToken);
                const encodedUserId = encodeURIComponent(user_id);

                fetch(`${SERVER_BASE_URL}/api/push-line-message/${encodedChannelAccessToken}/${encodedUserId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        messages: textareaInput,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Message sent:', data);
                    })
                    .catch(error => {
                        console.error('Error sending message:', error);
                    });
            }
            toast.success("群發完畢", {
                duration: 3000
            });
            setFetchFromDB(false);
        }
        handleDiologClose();
    }

    return (
        <>
            {
                fetchFromDB &&
                (
                    <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
            }

            <Container style={{ marginLeft: '60px', marginTop: '30px' }}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>傳送對象</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                        defaultValue="allMember"
                        onChange={handleReceiverChange}
                    >
                        <FormControlLabel value="allMember" control={<Radio />} label="給所有人" />
                        <FormControlLabel value="tagMember" control={<Radio />} label="依照標籤" />
                    </RadioGroup>
                </FormControl>
            </Container>

            <Container style={{ marginLeft: '60px', marginTop: '10px' }}>
                {
                    accordingToTag && (
                        <>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                defaultValue="IncludeTagMember"
                                onChange={handleTagRadioChange}
                            >
                                <FormControlLabel value="allTagMember" control={<Radio />} label="滿足所有標籤之客戶" />
                                <FormControlLabel value="IncludeTagMember" control={<Radio />} label="符合一個標籤以上之客戶" />
                            </RadioGroup>

                            <Autocomplete
                                multiple
                                // limitTags={5}
                                id="multiple-limit-tags"
                                options={tagList.filter(option => !tags.some(tag => tag.title === option.title))}
                                getOptionLabel={(option) => option.title}
                                // value={tags}
                                onChange={handleTagsChange}
                                isOptionEqualToValue={(option, value) => option.title === value.title}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option.title}
                                            {...getTagProps({ index })}
                                            style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', color: 'white', fontWeight: 'bold', marginRight: 5 }}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="選擇標籤" placeholder="加入標籤" />
                                )}
                                sx={{ width: '60vw', marginTop: '20px' }}
                            />
                        </>
                    )
                }
            </Container>

            <Container style={{ marginLeft: '60px', marginTop: '20px' }}>
                <TextareaAutosize
                    minRows={4}
                    placeholder="輸入訊息"
                    style={{ width: '60vw' }}
                    onChange={textareaInputChange}
                />
            </Container>

            <Container style={{ marginLeft: '60px', marginTop: '20px' }}>
                <Button className="py-2 rounded-pill" variant="primary" onClick={submitBroadcast} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>傳送</span></Button>
            </Container>

            <Dialog open={openDiolog} onClose={handleDiologClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定群發訊息嗎</DialogTitle>
                <DialogContent>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>本訊息會經由您的 Line @OA 傳送給 {reachPeopleCount} 個會員。<br />意味著您每月可用推播訊息數將減少 {reachPeopleCount}。<br />確定嗎？</span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDiologClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={finalSubmit} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BroadcastMessage;
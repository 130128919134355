import * as React from 'react';
import {
    Routes,
    Link,
    Route,
    useLocation,
} from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AiOutlineSchedule } from "react-icons/ai";
import { MdCurrencyExchange } from "react-icons/md";
import { PiCallBell } from "react-icons/pi";
import { RiCoupon2Line } from "react-icons/ri";
import { GoPeople } from "react-icons/go";
import { LuTags } from "react-icons/lu";
import { LuStore } from "react-icons/lu";
import { TbSpeakerphone } from "react-icons/tb";
import { RiDashboard2Line } from "react-icons/ri";
import { MdOutlineRememberMe } from "react-icons/md";

import Schedule from './Schedule';
import HowToGetPoints from '../Pages/HowToGetPoints';
import AppointmentMagt from '../Pages/AppointmentMagt';
import CouponMagt from '../Pages/CouponMagt';
import AllClients from '../Pages/AllClients';
import ClientDetail from '../Pages/ClientDetail';
import TagMagt from '../Pages/TagMagt';
import SelfAppointmentHistory from './SelfAppointmentHistory';
import SelfAppointmentHistoryDetail from './SelfAppointmentHistoryDetail';
import SelfCouponHistory from './SelfCouponHistory';
import SelfCouponHistoryDetail from './SelfCouponHistoryDetail';
import BusinessOperation from '../Pages/BusinessOperation';
import BroadcastMessage from '../Pages/BroadcastMessage';
import AddAppointmentManual from '../Pages/SubAppointmentMagt/AddAppointmentManual';
import Dashboard from '../Pages/Dashboard';
import StoreMember from '../Pages/StoreMember';
import StoreMemberDetail from '../Pages/SubStoreMember/StoreMemberDetail';
import toast from 'react-hot-toast';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const drawerWidth = '18vw';

const Sidebar = () => {
    const location = useLocation();

    const dispatch = useDispatch();

    const storeDB = useSelector(state => state.storeDB);

    useEffect(() => {
        let eventSource;
        const connectSSE = () => {
            eventSource = new EventSource(`${SERVER_BASE_URL}/events/${storeDB}`);

            eventSource.onmessage = (event) => {
                const newAppointment = JSON.parse(event.data);
                console.log(newAppointment);
                dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: newAppointment });

                toast('收到一筆新預約!', {
                    icon: '🙌',
                });

                const audio = new Audio('/mixkit-tile-game-reveal-960.wav');
                audio.play().catch(error => {
                    console.error('Error playing audio:', error);
                });
            };

            eventSource.onerror = (err) => {
                console.error('EventSource failed:', err);
                console.log("⚠️伺服器連線逾時，請重新登入");
                eventSource.close();
                setTimeout(() => {
                    console.log("Reconnecting...");
                    connectSSE();
                }, 3000); // 延遲3秒後重連
            };
        }

        if (storeDB) {
            connectSSE();
        }

        return () => {
            if (eventSource) {
                console.log("eventSource.close();");
                eventSource.close();
            }
        };

    }, [storeDB, dispatch]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(44, 35, 81, 0.8)' }}>
                <Toolbar>
                    <Typography variant="h5" noWrap component="div">
                        <span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: 'white', fontWeight: 'bold' }}>WEIZ 後台管理系統</span>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem
                            button
                            component={Link}
                            to="/business-operation"
                            selected={location.pathname === '/business-operation'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}>
                            <ListItemIcon>
                                <LuStore color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    營業管理
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/dashboard"
                            selected={location.pathname === '/dashboard'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}>
                            <ListItemIcon>
                                <RiDashboard2Line color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    儀表板
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/store-member"
                            selected={location.pathname === '/store-member'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}>
                            <ListItemIcon>
                                <MdOutlineRememberMe color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    店家成員
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/"
                            selected={location.pathname === '/'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}>
                            <ListItemIcon>
                                <AiOutlineSchedule color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    您的行程
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/all-clients"
                            selected={location.pathname === '/all-clients'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <GoPeople color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    所有客戶
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/tag-magt"
                            selected={location.pathname === '/tag-magt'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <LuTags color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    標籤管理
                                </Typography>
                            } />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            component={Link}
                            to="/how-to-get-points"
                            selected={location.pathname === '/how-to-get-points'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.1)',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <MdCurrencyExchange color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    點數累積方法設定
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/appointment-magt"
                            selected={location.pathname === '/appointment-magt'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.2)',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <PiCallBell color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    預約項目管理
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/coupon-magt"
                            selected={location.pathname === '/coupon-magt'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.2)',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <RiCoupon2Line color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    優惠兌換項目管理
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/broadcast-message"
                            selected={location.pathname === '/broadcast-message'}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: 'rgba(44, 35, 81, 0.2)',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <TbSpeakerphone color='#2C2351' size={20} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="body1" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>
                                    群發訊息
                                </Typography>
                            } />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 0, marginTop: '8vh' }}> {/* 調整內容與 Sidebar、Navbar 的間距 */}
                {/* 主要的內容放在這裡 */}
                <Routes>
                    <Route index element={<Schedule />} />
                    <Route path="/how-to-get-points" element={<HowToGetPoints />} />
                    <Route path="/appointment-magt" element={<AppointmentMagt />} />
                    <Route path="/coupon-magt" element={<CouponMagt />} />
                    <Route path="/all-clients" element={<AllClients />} />
                    <Route path="/client-detail" element={<ClientDetail />} />
                    <Route path="/tag-magt" element={<TagMagt />} />
                    <Route path="/self-appointment-history" element={<SelfAppointmentHistory />} />
                    <Route path="/self-appointment-history-detail" element={<SelfAppointmentHistoryDetail />} />
                    <Route path="/self-coupon-history" element={<SelfCouponHistory />} />
                    <Route path="/self-coupon-history-detail" element={<SelfCouponHistoryDetail />} />
                    <Route path="/business-operation" element={<BusinessOperation />} />
                    <Route path="/broadcast-message" element={<BroadcastMessage />} />
                    <Route path="/add-appointment-manual" element={<AddAppointmentManual />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/store-member" element={<StoreMember />} />
                    <Route path="/store-member-detail" element={<StoreMemberDetail />} />
                </Routes>
            </Box>
        </Box>
    );
}

export default Sidebar;
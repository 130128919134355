import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { RxDividerHorizontal } from "react-icons/rx";
import { Container, Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem, InputLabel, Select, FormControl } from '@mui/material';
import { IoMdAdd } from "react-icons/io";
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const SelfStoreMemberServices = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const member_id = searchParams.get('id');
    const member_username = searchParams.get('username');

    const storeDB = useSelector(state => state.storeDB);
    const appointmentList = useSelector(state => state.appointmentList);
    const selfAppointments = appointmentList.filter(appointment => appointment.partner === member_id);

    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [newAppointmentItem, setNewAppointmentItem] = useState({ title: '', takeTime: '', price: '', remark: '' });

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const removeItem = (item) => {
        setIsLoading(true);
        const confirmed = window.confirm(`確定要刪除${member_username}的「${item.title}」服務嗎？`);
        if (confirmed) {
            fetch(`${SERVER_BASE_URL}/api/remove-appointment-item/${storeDB}/${item.title}/${item.partner}`, {
                method: "DELETE",
            })
                .then(res => res.json())
                .then(data => {
                    dispatch({ type: 'SET_APPOINTMENT_LISTS', payload: data });
                    toast.success('刪除成功', {
                        duration: 5000,
                    });
                })
        }
        setIsLoading(false);
    }

    const handleAddItem = () => {
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/add-appointment-item/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "title": newAppointmentItem.title,
                "price": newAppointmentItem.price,
                "takeTime": newAppointmentItem.takeTime,
                "remark": newAppointmentItem.remark,
                "partner": member_id
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'SET_APPOINTMENT_LISTS', payload: data });
                toast.success('新增成功', {
                    duration: 5000,
                });
            })
        setIsLoading(false);
        handleDialogClose();
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAppointmentItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            {
                selfAppointments.length === 0 ?
                    (
                        <Container className='d-flex flex-column' style={{ marginTop: "30px", marginBottom: '10px' }}>
                            <p style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '16px' }}><i>{member_username}目前沒有提供任何可預約之服務項目</i></p>
                        </Container>
                    )
                    :
                    (
                        <Container className='d-flex flex-column' style={{ marginTop: "30px", marginBottom: '20px' }}>
                            {selfAppointments.map((appointment) => (
                                <Box
                                    key={appointment.title}
                                    sx={{
                                        borderColor: '#2C2351',
                                        borderWidth: '0.6px',
                                        borderStyle: 'solid',
                                        backgroundColor: 'transparent',
                                        width: '60vw',
                                        // height: '10vh',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0 10px',
                                        marginBottom: '10px', // 增加間距
                                    }}
                                >
                                    {/* <span style={{ color: '#0D7A7B', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', margin: '0' }}>{pointMethod.title}</span> */}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '16px', margin: '0' }}>{appointment.title}</span>
                                        <span style={{ color: 'rgba(44, 35, 81, 0.8)', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>服務單價: ${appointment.price}起, 預估時間: {appointment.takeTime}分, 服務備註: {appointment.remark === "" ? "-" : appointment.remark}</span>
                                    </div>
                                    <RxDividerHorizontal as={Button} onClick={() => removeItem(appointment)} style={{ marginRight: '10px', cursor: 'pointer' }} />
                                </Box>
                            ))}
                        </Container>
                    )
            }

            <Container className='d-flex flex-column' style={{ paddingBottom: '20vh' }}>
                <Box as={Button} onClick={handleDialogOpen} sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    "&:hover": {
                        backgroundColor: 'rgba(44, 35, 81, 0.1)', // 改变按钮的 hover 背景色
                    },
                    width: '60vw',
                    height: '10vh',
                    padding: '0 10px',
                    marginBottom: '10px', // 增加間距
                }}>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', marginRight: '5px' }}>點我新增{member_username}的預約項目</span>
                    <IoMdAdd color='#2C2351' />
                </Box>
            </Container >

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增{member_username}的預約項目</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="服務標題"
                        type="text"
                        fullWidth
                        name="title"
                        // value={newPointMethod.title}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="price"
                        label="服務單價(多少元起跳)"
                        type="number"
                        fullWidth
                        name="price"
                        // value={newPointMethod.content}
                        onChange={handleInputChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">預計花費時間(分鐘)</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            label="takeTime"
                            name="takeTime"
                            onChange={handleInputChange}
                        >
                            <MenuItem value={30}>30 分鐘</MenuItem>
                            <MenuItem value={60}>60 分鐘</MenuItem>
                            <MenuItem value={90}>90 分鐘</MenuItem>
                            <MenuItem value={120}>120 分鐘</MenuItem>
                            <MenuItem value={150}>150 分鐘</MenuItem>
                            <MenuItem value={180}>180 分鐘</MenuItem>
                            <MenuItem value={210}>210 分鐘</MenuItem>
                            <MenuItem value={240}>240 分鐘</MenuItem>
                            <MenuItem value={270}>270 分鐘</MenuItem>
                            <MenuItem value={300}>300 分鐘</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="remark"
                        label="此服務之備註"
                        type="text"
                        fullWidth
                        name="remark"
                        // value={newPointMethod.content}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={handleAddItem} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default SelfStoreMemberServices;
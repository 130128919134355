import { useState } from 'react';
import { Container, Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { RxDividerHorizontal } from "react-icons/rx";
import { IoMdAdd } from "react-icons/io";
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const HowToGetPoints = () => {
    const dispatch = useDispatch();
    const storeDB = useSelector(state => state.storeDB);
    const pointMethodList = useSelector(state => state.pointMethodList);

    const [isLoading, setIsLoading] = useState(false);
    const [newPointMethod, setNewPointMethod] = useState({ title: '', content: '' });
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPointMethod(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // 新增點數累積方法
    const handleAddItem = () => {
        setIsLoading(true);
        // Add logic to dispatch new item to Redux store
        setNewPointMethod({ title: '', content: '' });

        fetch(`${SERVER_BASE_URL}/api/add-point-method/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "title": newPointMethod.title,
                "content": newPointMethod.content
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch({ type: 'SET_POINT_METHODS', payload: data });
                toast.success('新增成功', {
                    duration: 5000,
                });
            })
        handleClose();
        setIsLoading(false);

    };

    const removeItem = (method) => {
        setIsLoading(true);
        const confirmed = window.confirm(`確定要刪除 "${method.title}" 嗎？`);
        const methodTitle = encodeURIComponent(method.title);
        if (confirmed) {
            fetch(`${SERVER_BASE_URL}/api/remove-point-method/${storeDB}/${methodTitle}`, {
                method: "DELETE",
            })
                .then(res => res.json())
                .then(data => {
                    dispatch({ type: 'SET_POINT_METHODS', payload: data });
                    toast.success('刪除成功', {
                        duration: 5000,
                    });
                })
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <Container className='d-flex flex-column' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>所有點數累積方法</span>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '14px' }}><i>此頁內容會顯示於您客戶手機端的「會員主頁」</i></span>
            </Container>


            {
                pointMethodList.length === 0 ?
                    (
                        <Container className='d-flex flex-column' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '10px' }}>
                            <p style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px' }}><i>目前沒有提供任何點數累積方法</i></p>
                        </Container>
                    )
                    :
                    (
                        <Container className='d-flex flex-column' style={{ marginLeft: "30px" }}>
                            {pointMethodList.map((pointMethod) => (
                                <Box
                                    key={pointMethod.title}
                                    sx={{
                                        borderColor: '#2C2351',
                                        borderWidth: '0.6px',
                                        borderStyle: 'solid',
                                        backgroundColor: 'transparent',
                                        width: '60vw',
                                        height: '10vh',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0 10px',
                                        marginBottom: '10px', // 增加間距
                                    }}
                                >
                                    {/* <span style={{ color: '#0D7A7B', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', margin: '0' }}>{pointMethod.title}</span> */}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '16px', margin: '0' }}>{pointMethod.title}</span>
                                        <span style={{ color: 'rgba(44, 35, 81, 0.8)', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>{pointMethod.content}</span>
                                    </div>
                                    <RxDividerHorizontal as={Button} onClick={() => removeItem(pointMethod)} style={{ marginRight: '10px', cursor: 'pointer' }} />
                                </Box>
                            ))}
                        </Container>

                    )
            }
            <Container className='d-flex flex-column' style={{ marginLeft: "30px", paddingBottom: '20vh' }}>
                <Box as={Button} onClick={handleOpen} sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    "&:hover": {
                        backgroundColor: 'rgba(44, 35, 81, 0.1)', // 改变按钮的 hover 背景色
                    },
                    width: '60vw',
                    height: '10vh',
                    padding: '0 10px',
                    marginBottom: '10px', // 增加間距
                }}>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', marginRight: '5px' }}>點我新增累積方法</span>
                    <IoMdAdd color='#2C2351' />
                </Box>
            </Container>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增點數累積方法</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="標題"
                        type="text"
                        fullWidth
                        name="title"
                        value={newPointMethod.title}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="content"
                        label="內容描述"
                        type="text"
                        fullWidth
                        name="content"
                        value={newPointMethod.content}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={handleAddItem} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default HowToGetPoints;
import { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import EditCouponItem from './SubCouponMagt/EditCouponItem';
import ListAllCouponHistory from './SubCouponMagt/ListAllCouponHistory';

const CouponMagt = () => {
    const [activeKey, setActiveKey] = useState("edit-coupon-item");

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };


    return (
        <>
            <Container className='d-flex flex-column' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                {/* <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>商家資訊</span> */}
                <Nav variant="underline" defaultActiveKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="edit-coupon-item"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>編輯客戶可兌換之優惠項目</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="list-all-coupon-history"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>查看所有用戶之歷史兌換</span></Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeKey === "edit-coupon-item" && <EditCouponItem />}
                {activeKey === "list-all-coupon-history" && <ListAllCouponHistory />}
            </Container>
        </>
    );
}

export default CouponMagt;
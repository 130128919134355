import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { Container, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { nanoid } from 'nanoid';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const TagMagt = () => {

    const dispatch = useDispatch();

    const storeDB = useSelector(state => state.storeDB);
    const tagList = useSelector(state => state.tagList);

    const [isLoading, setIsLoading] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const [addedTagName, setAddedTagName] = useState('');
    const [addedTagDescription, setAddedTagDescription] = useState('');

    const [editedTagId, setEditedTagId] = useState('');
    const [editedTagName, setEditedTagName] = useState('');
    const [editedTagDescription, setEditedTagDescription] = useState('');

    // [關]: Dialog「修改標籤」彈跳視窗的開關 
    const handleClose = () => {
        setOpenEdit(false);
    };

    // [開]: Dialog彈跳視窗的開關 
    // 且設定是要「修改」哪一個標籤（哪一row的修改按鈕被點選了）
    const handleEdit = (row) => {
        setEditedTagId(row.id);
        setEditedTagName(row.title);
        setEditedTagDescription(row.description);
        setOpenEdit(true);
    };

    // Dialog視窗按下「確定修改」後執行
    const submitEdit = () => {
        // alert(`標籤 ID: ${editedTagId}\n標籤名: ${editedTagName}\n描述: ${editedTagDescription}`);
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/edit-tag/${storeDB}/${editedTagId}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: editedTagName,
                description: editedTagDescription
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'SET_TAG_LIST', payload: data });
                setIsLoading(false);
                window.location.reload();
                toast.success('修改完成', {
                    duration: 5000,
                });
            })
        setOpenEdit(false);
    }

    // [開]: Dialog「新增標籤」彈跳視窗的開關 
    const handleAdd = () => {
        setOpenAdd(true);
    }

    // [關]: Dialog「新增標籤」彈跳視窗的開關 
    const handleAddWindowClose = () => {
        setOpenAdd(false);
    }

    // Dialog選取「確定新增標籤」
    const handleAddTag = () => {
        // alert(addedTagName + '\n' + addedTagDescription);
        const uniqId = nanoid();
        fetch(`${SERVER_BASE_URL}/api/add-tag/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "id": uniqId,
                "title": addedTagName,
                "description": addedTagDescription
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch({ type: 'SET_TAG_LIST', payload: data });
                toast.success('新增成功', {
                    duration: 5000,
                });
                setIsLoading(false);
            })
        handleAddWindowClose();
    }

    // 當某一row的「刪除按鈕」被點選後執行
    const handleDelete = (row) => {
        // 處理刪除按鈕點擊事件
        const confirmed = window.confirm(`確定要刪除「${row.title}」 嗎?`);
        if (confirmed) {
            setIsLoading(true);
            fetch(`${SERVER_BASE_URL}/api/remove-tag/${storeDB}/${row.id}`, {
                method: "DELETE",
            })
                .then(res => res.json())
                .then(data => {
                    dispatch({ type: 'SET_TAG_LIST', payload: data });
                    // window.location.reload(); // Why this line?
                    toast.success('刪除成功', {
                        duration: 5000,
                    });
                    setIsLoading(false);
                })
        }

    };

    const columns = [
        { field: 'id', headerName: <span style={{ fontWeight: 'bold', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>id</span>, width: 0 },
        { field: 'title', headerName: <span style={{ fontWeight: 'bold', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>標籤名</span>, width: 200 },
        { field: 'description', headerName: <span style={{ fontWeight: 'bold', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>描述</span>, width: 500, },
        {
            field: 'operation', headerName: <span style={{ fontWeight: 'bold', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>操作</span>, width: 200,
            renderCell: (params) => (
                <div>
                    <Button variant="outline-primary" size="sm" style={{ border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }} onClick={() => handleEdit(params.row)}>修改</Button>{'｜'}
                    <Button variant="outline-danger" size="sm" style={{ border: 'none', fontFamily: 'Fraunces, Arial, sans-serif' }} onClick={() => handleDelete(params.row)}>刪除</Button>
                </div>
            ),
        },
    ];

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                <Button className="py-2 rounded-pill" variant="primary" onClick={handleAdd} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>＋新增標籤</span></Button>
            </Container>

            <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", paddingBottom: '50px' }}>
                <div style={{ height: '80vh', width: '70vw' }}>
                    <DataGrid
                        rows={tagList}
                        columns={columns}
                        style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', cursor: 'pointer' }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    id: false,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        columnBuffer={-1}
                    // checkboxSelection
                    />
                </div>
            </Container>


            {/* 「修改標籤」彈跳視窗 */}
            <Dialog open={openEdit} onClose={handleClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>修改標籤</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="標籤名"
                        type="text"
                        fullWidth
                        name="title"
                        value={editedTagName}
                        onChange={(e) => setEditedTagName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="描述"
                        type="text"
                        fullWidth
                        name="description"
                        value={editedTagDescription}
                        onChange={(e) => setEditedTagDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={submitEdit} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定修改</Button>
                </DialogActions>
            </Dialog>


            {/* 「新增標籤」彈跳視窗 */}
            <Dialog open={openAdd} onClose={handleAddWindowClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增標籤</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="標籤名"
                        type="text"
                        fullWidth
                        name="title"
                        // value={editedTagName}
                        onChange={(e) => setAddedTagName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="描述"
                        type="text"
                        fullWidth
                        name="description"
                        // value={editedTagDescription}
                        onChange={(e) => setAddedTagDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddWindowClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={handleAddTag} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定新增</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TagMagt;
import { Navbar, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const SelfCouponHistory = (userId) => {
    const navigate = useNavigate();

    const couponHistory = useSelector(state => state.couponHistory);
    const selfHistory = couponHistory.filter(coupon => coupon.userId === userId.userId);
    const len_historyData = selfHistory.length;

    const navigateToDetailPage = (navbar) => {
        navigate("/self-coupon-history-detail", {
            state: { navbar }
        });
    }

    return (
        len_historyData === 0 ?
            (
                <p style={{ marginTop: '30px', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px' }}>目前尚未有兌換紀錄!</p>
            )
            :
            (
                selfHistory.map(navbar => (
                    <Navbar key={navbar.id} as={Button} onClick={() => { navigateToDetailPage(navbar) }} style={{ borderBottomColor: 'rgba(44, 35, 81, 0.2)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%', borderTopColor: 'transparent', borderLeftColor: 'transparent', borderRightColor: 'transparent', marginTop: '10px', width: '60vw', backgroundColor: 'transparent' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ flex: '0 0 70%', marginBottom: '5px', textAlign: 'left' }}>
                                <Navbar.Brand style={{ color: '#fff', fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif', whiteSpace: 'normal' }}>
                                    <span style={{ fontSize: '16px', color: '#2C2351' }}>兌換項目: {navbar.title}</span>
                                </Navbar.Brand>
                            </div>
                            <div style={{ flex: '0 0 30%', display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '12px', color: '#2C2351' }}>於 {navbar.currentTime} 兌換</span>
                            </div>
                        </div>
                        <Navbar.Collapse className="justify-content-end">
                            <FaAngleRight color='#2C2351' />
                        </Navbar.Collapse>
                    </Navbar>
                ))
            )
    );
}

export default SelfCouponHistory;
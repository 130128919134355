import { useState, useEffect } from 'react';
import './App.css';
import Sidebar from './Components/Sidebar';
import { useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const urlParams = new URLSearchParams(window.location.search);
if (!urlParams.has('storeDB') && process.env.REACT_APP_STORE_DB) {
  // 如果 URL 中沒有 storeDB 參數，但 env 有，就添加進來
  urlParams.append('storeDB', process.env.REACT_APP_STORE_DB);
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
}

function App() {
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetchFromDB, setFetchFromDB] = useState(false);

  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const [setupStoreDBName, setSetupStoreDBName] = useState(false);
  const [storeDB, setStoreDB] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const storeDBParam = params.get('storeDB');
    if (storeDBParam) {
      setStoreDB(storeDBParam);
      dispatch({ type: 'SET_STORE_DB', payload: storeDBParam });
      // 在這裡可以進行相應的初始化操作，例如根據 storeId 加載相應的資料
      setSetupStoreDBName(true);
    }
  }, []);

  useEffect(() => {
    if (setupStoreDBName) {
      setFetchFromDB(true);
      fetch(`${SERVER_BASE_URL}/api/get-all-lists/${storeDB}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_POINT_METHODS', payload: data.pointMethod });
          dispatch({ type: 'SET_APPOINTMENT_LISTS', payload: data.appointmentList });
          dispatch({ type: 'SET_COUPON_LIST', payload: data.couponList });
          dispatch({ type: 'SET_TAG_LIST', payload: data.tagsList });
          // dispatch({ type: 'SET_OPEN_CLOSE_DATE_TIME', payload: data.openCloseDateTime });
          setFetchFromDB(false);
        })
    }
  }, [isLoggedIn, setupStoreDBName]);

  useEffect(() => {
    if (setupStoreDBName) {
      setFetchFromDB(true);
      fetch(`${SERVER_BASE_URL}/api/get-all-user-info/${storeDB}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_USER_INFO', payload: data });
        })

      setFetchFromDB(false);
    }
  }, [isLoggedIn, setupStoreDBName]);

  useEffect(() => {
    if (setupStoreDBName) {
      setFetchFromDB(true);
      fetch(`${SERVER_BASE_URL}/api/get-all-appointment-history/${storeDB}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: data });
        })

      setFetchFromDB(false);
    }
  }, [isLoggedIn, setupStoreDBName]);

  useEffect(() => {
    if (setupStoreDBName) {
      setFetchFromDB(true);
      fetch(`${SERVER_BASE_URL}/api/get-all-coupon-history/${storeDB}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_COUPON_HISTORY', payload: data });
        })

      setFetchFromDB(false);
    }
  }, [isLoggedIn, setupStoreDBName]);

  useEffect(() => {
    if (isLoggedIn && setupStoreDBName) {
      setFetchFromDB(true);
      fetch(`${SERVER_BASE_URL}/api/get-store-partners/${storeDB}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_STORE_PARTNERS', payload: data });
        })
    }

    setFetchFromDB(false);
  }, [isLoggedIn, setupStoreDBName]);

  const changeAccountInput = (event) => {
    setAccount(event.target.value);
  }

  const changePasswordsInput = (event) => {
    setPassword(event.target.value);
  }

  const handleLogin = async () => {
    fetch(`${SERVER_BASE_URL}/api/backend-login/${storeDB}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        "account": account,
        "password": password,
      })
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(error => {
            throw new Error(error.error);
          });
        }
        return res.json();
      })
      .then(data => {
        console.log(data);
        setIsLoggedIn(true);
        // 成功登录处理
      })
      .catch(err => {
        alert(err.message);
      });
  }

  return (
    <>
      <Toaster />
      {
        !isLoggedIn ?
          (
            <Modal show={!isLoggedIn} centered>
              <Modal.Header>
                <Modal.Title>
                  <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>登入</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ color: '#2C2351' }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="帳號"
                  className="mb-3"
                  onChange={changeAccountInput}
                >
                  <Form.Control type="text" placeholder="name@example.com" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="密碼">
                  <Form.Control type="password" placeholder="Password" onChange={changePasswordsInput} />
                </FloatingLabel>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={handleLogin}
                  style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}
                >
                  Sign In
                </Button>
              </Modal.Footer>
            </Modal>
          )
          :
          (
            <div className="app-container">
              {
                fetchFromDB ?
                  (
                    <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )
                  :
                  (<Sidebar />)
              }
            </div>
          )
      }
    </>
  );
}

export default App;

import { MdConstruction } from "react-icons/md";
const Dashboard = () => {
    return (
        <>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // 将父元素高度设置为视窗高度，实现垂直居中
                }}
            >
                <MdConstruction size={40} style={{ color: '#2C2351' }} />
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>In Progress...</span>
            </div>
        </>
    );
}

export default Dashboard;
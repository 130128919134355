import * as React from 'react';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import {
    GridRowModes,
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridCellParams
} from '@mui/x-data-grid';
import { nanoid } from 'nanoid';
import { ChromePicker } from 'react-color';


const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const StoreMember = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const storeDB = useSelector(state => state.storeDB);
    const storePartners = useSelector(state => state.storePartners);
    const [rows, setRows] = useState(storePartners);
    const [rowModesModel, setRowModesModel] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEditColor, setOpenEditColor] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [newDescription, setNewDescription] = useState('');

    const [newColor, setNewColor] = useState('#000000'); // 默认颜色为黑色
    const [dialogColorPickerOpen, setDiologColorPickerOpen] = useState(false);


    const handleAddWindowOpen = () => setOpenAdd(true);
    const handleAddWindowClose = () => setOpenAdd(false);

    const handleEditWindowOpen = () => setOpenEditColor(true);
    const handleEditWindowClose = () => setOpenEditColor(false);

    const [selectedRow, setSelectedRow] = useState(null); // 用于存储当前选中的行


    const handleColorChange = (color) => setNewColor(color.hex);

    const handleRowClick = (rowData) => {
        navigate(`/store-member-detail?id=${rowData.id}&username=${rowData.username}`);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setIsLoading(true);
        setRows(rows.filter((row) => row.id !== id));
        fetch(`${SERVER_BASE_URL}/api/remove-store-partners/${storeDB}/${id}`, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(data => {
                dispatch({ type: 'SET_STORE_PARTNERS', payload: data });
                toast.success("已刪除成員", {
                    duration: 3000
                });
            })
        setIsLoading(false);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        setIsLoading(true);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        fetch(`${SERVER_BASE_URL}/api/edit-store-partners/${storeDB}/${updatedRow.id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: updatedRow.username,
                description: updatedRow.description,
                color: updatedRow.color
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch({ type: 'SET_STORE_PARTNERS', payload: data });
                toast.success("已修改成員資料", {
                    duration: 3000
                });
            })
        setIsLoading(false);
        return updatedRow;
    };

    const addNewMember = () => {
        setIsLoading(true);
        const _id = nanoid();
        fetch(`${SERVER_BASE_URL}/api/edit-store-partners/${storeDB}/${_id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: newUsername,
                description: newDescription,
                color: newColor
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch({ type: 'SET_STORE_PARTNERS', payload: data });
                setRows(data);
                setOpenAdd(false);
                toast.success(`新增成功!\n記得去新增成員的預約項目❤️`, {
                    duration: 3000
                });
            })
        setIsLoading(false);
    }

    const handleEditConfirm = () => {
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/edit-store-partners/${storeDB}/${selectedRow.id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                color: newColor
            })
        })
            .then(res => res.json())
            .then(data => {
                dispatch({ type: 'SET_STORE_PARTNERS', payload: data });
                setRows(data);
                setOpenAdd(false);
                toast.success(`夥伴代表色修改成功!`, {
                    duration: 3000
                });
            })
        handleEditWindowClose();
        setIsLoading(false);
    }

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    const columns = [
        { field: 'username', editable: true, headerName: <span style={{ fontWeight: 'bold', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>username</span>, width: 120 },
        { field: 'description', editable: true, headerName: <span style={{ fontWeight: 'bold', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>description</span>, width: 200 },
        {
            field: 'color',
            headerName: '代表色',
            width: 120,
            sortable: false,
            renderCell: (params) => {
                const { value } = params;

                const handleColorButtonClick = (e) => {
                    e.stopPropagation();
                    // console.log(params.row);
                    setSelectedRow(params.row)
                    // alert(`你点击了代表色按钮，颜色值为: ${value}`);
                    handleEditWindowOpen();
                };

                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }} onClick={handleColorButtonClick}>
                            <div
                                style={{ backgroundColor: value, width: '20px', height: '20px', borderRadius: '50%', marginRight: '8px', cursor: 'pointer' }}
                            />
                            <span>{value}</span>
                        </div>
                    </>
                );
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                <Button className="py-2 rounded-pill" onClick={handleAddWindowOpen} variant="primary" style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>＋新增夥伴成員</span></Button>
            </Container>

            <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", paddingBottom: '50px' }}>
                <div style={{ height: '80vh', width: '70vw' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', cursor: 'pointer' }}
                        onRowClick={(rowData) => handleRowClick(rowData.row)}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        getRowId={(row) => row.id}
                    />
                </div>
            </Container>

            {/* 「新增商家成員」彈跳視窗 */}
            <Dialog open={openAdd} onClose={handleAddWindowClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增商家成員</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="成員名稱"
                        type="text"
                        fullWidth
                        name="username"
                        // value={editedTagName}
                        onChange={(e) => setNewUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="描述"
                        type="text"
                        fullWidth
                        name="description"
                        // value={editedTagDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="color"
                        label="代表色"
                        type="text"
                        fullWidth
                        name="color"
                        value={newColor}
                        onChange={(e) => setNewColor(e.target.value)}
                        onClick={() => setDiologColorPickerOpen(!dialogColorPickerOpen)}
                    />
                    {dialogColorPickerOpen && (
                        <ChromePicker
                            color={newColor}
                            onChange={handleColorChange}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddWindowClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={addNewMember} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定新增</Button>
                </DialogActions>
            </Dialog>


            {/* 「更改顏色」彈跳視窗 */}
            <Dialog open={openEditColor} onClose={handleEditWindowClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>更改夥伴代表色</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="self-color"
                        label="代表色"
                        type="text"
                        fullWidth
                        name="self-color"
                        value={newColor}
                        onChange={(e) => setNewColor(e.target.value)}
                        onClick={() => setDiologColorPickerOpen(!dialogColorPickerOpen)}
                    />
                    {dialogColorPickerOpen && (
                        <ChromePicker
                            color={newColor}
                            onChange={handleColorChange}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditWindowClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={handleEditConfirm} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定修改</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default StoreMember;
import { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import EditAppointmentItem from './SubAppointmentMagt/EditAppointmentItem';
import ListAllAppointment from './SubAppointmentMagt/ListAllAppointment';

const AppointmentMagt = () => {
    const [activeKey, setActiveKey] = useState("edit-appointment-item");

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    return (
        <>
            <Container className='d-flex flex-column' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                {/* <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>商家資訊</span> */}
                <Nav variant="underline" defaultActiveKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="edit-appointment-item"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>編輯客戶可預約之項目</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="list-all-appointment"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>查看所有預約歷史</span></Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeKey === "edit-appointment-item" && <EditAppointmentItem />}
                {activeKey === "list-all-appointment" && <ListAllAppointment />}
            </Container>

        </>
    );
}

export default AppointmentMagt;
import { useNavigate, useLocation } from 'react-router-dom';
import { IoReturnUpBack } from "react-icons/io5";
import { Container, Card } from 'react-bootstrap';

const SelfCouponHistoryDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const rowData = location.state.navbar;
    // console.log(rowData);
    return (
        <>
            <Container className='d-flex flex-column' style={{ marginTop: "5px", marginLeft: "5px", marginBottom: '20px' }}>
                <IoReturnUpBack size={30} onClick={() => { navigate(-1) }} style={{ color: '#2C2351', marginTop: '30px', marginLeft: '30px', cursor: 'pointer' }} />
            </Container>

            <Card style={{ width: '60vw', marginLeft: '60px' }}>
                <Card.Body>
                    <Card.Title><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>編號: #{rowData.id}</span></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '14px' }}>{rowData.currentTime}</span></Card.Subtitle>
                    <Card.Text>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>兌換人ID: {rowData.userId}</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>兌換項目: {rowData.title}</li></span>
                        <br />
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><li>花費點數: {rowData.pointCost}點</li></span>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default SelfCouponHistoryDetail;
import { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';

import StoreSetting from './SubBusinessOperation/StoreSetting';
import DisableBookTime from './SubBusinessOperation/DisableBookTime';
import ChangeAccPass from './SubBusinessOperation/ChangeAccPass';

const BussinessOperation = () => {
    const [activeKey, setActiveKey] = useState("store-setting");

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    return (
        <>

            <Container className='d-flex flex-column' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                {/* <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>商家資訊</span> */}
                <Nav variant="underline" defaultActiveKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="store-setting"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>商家資訊</span></Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link className="navLink" eventKey="manual"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>手動開啟/關閉預約時段</span></Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="change-acc-pass"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: '#2C2351', fontSize: '18px', fontWeight: 'bold' }}>更改後台帳號密碼</span></Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeKey === "store-setting" && <StoreSetting />}
                {/* {activeKey === "manual" && <DisableBookTime />} */}
                {activeKey === "change-acc-pass" && <ChangeAccPass />}
            </Container>

        </>
    );
}

export default BussinessOperation;
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, FloatingLabel, Form, Button } from 'react-bootstrap';
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IoReturnUpBack } from "react-icons/io5";
import dayjs from 'dayjs';
import crypto from 'crypto-js';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import 'dayjs/locale/zh-tw'; // 引入 Day.js 的台北時區
import toast from 'react-hot-toast';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

dayjs.locale('zh-tw'); // 設置 Day.js 使用台北時區

const theme = createTheme({
    typography: {
        fontFamily: 'Fraunces, Arial, sans-serif', // 設置字型
    },
    components: {
        MuiTimePicker: {
            styleOverrides: {
                root: {
                    color: '#2C2351', // 設置文字顏色
                },
            },
        },
    },
});

const AddAppointmentManual = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const storeDB = useSelector(state => state.storeDB);
    const storePartners = useSelector(state => state.storePartners);

    const [showConfirm, setShowConfirm] = useState(false);
    const [title, setTitle] = useState('');
    const [partner, setPartner] = useState('');
    const [bookDate, setBookDate] = useState('');
    const [bookTime, setBookTime] = useState('');
    const [price, setPrice] = useState('');
    const [takeTime, setTakeTime] = useState('');
    const [fetchFromDB, setFetchFromDB] = useState(false);

    const generateRandomCode = (title) => {
        const timestamp = Math.floor(Date.now() / 1000); // 获取当前时间戳（精确到秒）
        const inputString = `${timestamp}-${title}`; // 将当前时间戳与要加入的字符串进行拼接
        const hashValue = crypto.MD5(inputString).toString(); // 计算 MD5 哈希值

        // 截取哈希值的一部分作为随机码
        const randomCodePart = hashValue.substring(0, 10).toUpperCase(); // 截取前 10 位并转换为大写

        return randomCodePart; // 设置随机码
    };

    const nowDateTime = () => {
        // 目前時間
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要 +1
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        // End
        return formattedTime;
    }

    const backToPrevious = () => {
        navigate(-1, {
            state: { refresh: true },
        });
    }

    const handleBookTimeChange = (value) => {
        const formattedBookTime = dayjs(value).format('HH:mm');
        setBookTime(formattedBookTime);
    }

    const handleBookDateChange = (value) => {
        const formattedBookDate = dayjs(value).format('YYYY-MM-DD');
        setBookDate(formattedBookDate);
    }

    const submitBtn = () => {
        setShowConfirm(true);
    }

    const confirmClose = () => {
        setShowConfirm(false);
    }

    const finalConfirm = () => {
        console.log("9999999");
        setFetchFromDB(true);
        fetch(`${SERVER_BASE_URL}/api/add-appointment-history-return-all/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "id": generateRandomCode(title),
                "userId": "admin",
                "title": title,
                "currentTime": nowDateTime(),
                "bookDate": bookDate,
                "bookTime": bookTime,
                "price": price,
                "takeTime": takeTime,
                "remark": "",
                "commentToStore": "",
                "partner": partner
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: data });
                toast.success('預約成功', {
                    duration: 5000,
                });
            })

        setShowConfirm(false);
        setFetchFromDB(false);
        backToPrevious();
    }

    return (
        <>
            {
                fetchFromDB ?
                    (
                        <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )
                    :
                    (
                        <>
                            <Container className='d-flex flex-column' style={{ marginTop: "5px", marginLeft: "5px", marginBottom: '20px' }}>
                                <IoReturnUpBack size={30} onClick={() => { backToPrevious(); }} style={{ color: '#2C2351', marginTop: '30px', marginLeft: '30px', cursor: 'pointer' }} />
                            </Container>

                            <Container className='d-flex flex-column' style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", marginBottom: '20px' }}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="服務項目"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="此服務之名稱" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }} onChange={(e) => setTitle(e.target.value)} />
                                </FloatingLabel>

                                <FormControl fullWidth>
                                    <InputLabel id="partner-select-label">選擇成員</InputLabel>
                                    <Select
                                        labelId="partner-select-label"
                                        id="partner"
                                        // value={selectedPartner}
                                        label="誰開的服務"
                                        name="partner"
                                        onChange={(e) => { setPartner(e.target.value) }}
                                    >
                                        {storePartners.map((partner) => (
                                            <MenuItem key={partner.id} value={partner.id}>
                                                {partner.username}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <ThemeProvider theme={theme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-tw">
                                        <DemoContainer components={['DatePicker', 'TimePicker']}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                <DatePicker
                                                    label="欲預約日期"
                                                    onChange={handleBookDateChange}
                                                    sx={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                                />
                                                <TimePicker
                                                    label="欲預約時間"
                                                    onChange={handleBookTimeChange}
                                                    sx={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                                />
                                            </div>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </ThemeProvider>

                                <div style={{ marginTop: '20px' }}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="服務金額"
                                        className="mb-3"
                                    >
                                        <Form.Control type="number" placeholder="您的品牌/商家名稱" style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }} onChange={(e) => setPrice(e.target.value)} />
                                    </FloatingLabel>
                                </div>

                                <div style={{ marginTop: '20px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>服務所花時間</span></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={serviceTimeInterval}
                                            label="服務所花時間"
                                            style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}
                                            onChange={(e) => setTakeTime(e.target.value)}
                                        >
                                            <MenuItem value={"30"}>30 分鐘</MenuItem>
                                            <MenuItem value={"60"}>60 分鐘</MenuItem>
                                            <MenuItem value={"90"}>90 分鐘</MenuItem>
                                            <MenuItem value={"120"}>120 分鐘</MenuItem>
                                            <MenuItem value={"150"}>150 分鐘</MenuItem>
                                            <MenuItem value={"180"}>180 分鐘</MenuItem>
                                            <MenuItem value={"210"}>210 分鐘</MenuItem>
                                            <MenuItem value={"240"}>240 分鐘</MenuItem>
                                            <MenuItem value={"270"}>270 分鐘</MenuItem>
                                            <MenuItem value={"300"}>300 分鐘</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <Container className='d-flex flex-column align-items-center' style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                                    <Button className="py-2 rounded-pill" variant="primary" onClick={submitBtn} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>送出</span></Button>
                                </Container>
                            </Container>

                            <Dialog open={showConfirm} onClose={confirmClose}>
                                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定手動預約</DialogTitle>
                                <DialogContent>
                                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>服務項目: {title}<br /><br />預約時間: {bookDate} {bookTime}<br /><br />服務金額: ${price} 起<br /><br />服務所花時間: {takeTime} 分鐘</span>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={confirmClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                                    <Button onClick={finalConfirm} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', border: 'none', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
            }

        </>
    );
}

export default AddAppointmentManual;
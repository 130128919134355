import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoReturnUpBack } from "react-icons/io5";
import { Container, Nav, Button } from 'react-bootstrap';
import SelfStoreMemberServices from './SelfStoreMemberServices';
import SelfStoreMemberAppointment from './SelfStoreMemberAppointment';
import SelfStoreMemberForceTimeMagt from "./SelfStoreMemberForceTimeMagt";

const StoreMemberDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const member_id = searchParams.get('id');
    const member_username = searchParams.get('username');

    const [activeKey, setActiveKey] = useState("self-store-member-services"); //預設先顯示「預約歷史」tab

    // 換 tab
    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    const backToPrevious = () => {
        navigate(-1, {
            state: { refresh: true },
        });
    }

    return (
        <>
            <Container className='d-flex flex-column' style={{ marginTop: "5px", marginLeft: "5px", marginBottom: '20px' }}>
                <IoReturnUpBack size={30} onClick={() => { backToPrevious(); }} style={{ color: '#2C2351', marginTop: '30px', marginLeft: '30px', cursor: 'pointer' }} />
            </Container>
            <Container style={{ marginLeft: '60px', marginTop: '30px' }}>
                <Nav variant="underline" defaultActiveKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="self-store-member-services"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>{member_username}提供之服務</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="self-store-member-appointment"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>{member_username}的所有預約</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="self-store-member-force-time-magt"><span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>打開/關閉 {member_username} 的時段</span></Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeKey === "self-store-member-services" && <SelfStoreMemberServices id={member_id} username={member_username} />}
                {activeKey === "self-store-member-appointment" && <SelfStoreMemberAppointment id={member_id} username={member_username} />}
                {activeKey === "self-store-member-force-time-magt" && <SelfStoreMemberForceTimeMagt id={member_id} username={member_username} />}
            </Container>
        </>
    );
}

export default StoreMemberDetail;
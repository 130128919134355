import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import toast from 'react-hot-toast';
import flexModel from '../Components/FlexModel.json';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;


const AllClients = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedMultiUsers, setSelectedMultiUsers] = useState([]);


    const [inputValue, setInputValue] = useState('');
    const [assignPointDialog, setAssignPointDialog] = useState(false);
    const [newPoints, setNewPoints] = useState('');

    const userInfo = useSelector(state => state.userInfo);
    const storeDB = useSelector(state => state.storeDB);

    const [channelAccessToken, setChannelAccessToken] = useState("");

    useEffect(() => {
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/get-store-setting/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                // dispatch({ type: 'SET_STORE_SETTING', payload: data[0] });
                setChannelAccessToken(data[0].channelAccessToken)
            })
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/get-all-users/${storeDB}`)
            .then(response => response.json())
            .then(data => {
                dispatch({ type: 'SET_USER_INFO', payload: data });
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching user info:', error);
                setIsLoading(false);
            });
    }, [storeDB, dispatch]);

    // 新增: 控制發放點數按鈕的狀態
    const [isAssignButtonEnabled, setIsAssignButtonEnabled] = useState(false);

    const columns = [
        {
            field: 'userId',
            headerName: 'ID',
            width: 300,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'displayName',
            headerName: '名稱',
            width: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={params.row.pictureUrl} alt="Avatar"
                        style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                    {params.value}
                </div>
            ),
        },
        {
            field: 'phone',
            headerName: '電話',
            width: 120,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'points',
            headerName: '點數',
            type: 'number',
            width: 80,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'tag',
            headerName: '標籤',
            width: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>
                    {params.value.map((tag, index) => (
                        <button key={index}
                            style={{ border: 'none', background: 'none', color: '#2C2351', cursor: 'pointer', }}>
                            {tag}
                        </button>
                    ))}
                </div>
            ),
        },
    ];

    const generateJson = (params) => {
        // 將模板中的佔位符替換為參數值
        const jsonString = JSON.stringify(flexModel)
            .replace('{{emoji}}', params.emoji)
            .replace('{{eventTitle}}', params.eventTitle)
            .replace('{{currentTime}}', params.currentTime)
            .replace('{{eventDescription}}', params.eventDescription)
            .replace('{{eventPrice}}', params.eventPrice)
        // .replace('{{eventTakeTime}}', params.eventTakeTime)
        // .replace('{{eventRemark}}', params.eventRemark)
        // .replace('{{eventCommentToStore}}', params.eventCommentToStore);

        return JSON.parse(jsonString);
    };

    const handleAutocompleteChange = (event, value) => {
        const selectedUser = userInfo.find(user => `${user.displayName} - ${user.phone}` === value);
        setSelectedUser(selectedUser?.userId || null);
    };

    const handleInputChange = (event, value) => {
        setInputValue(value);
        if (!value) {
            setSelectedUser(null);
        }
    };

    const handleRowClick = (rowData) => {
        navigate(`/client-detail?userId=${rowData.userId}`);
    };

    // 開啟發放點數對話框
    const openAssignPointDialog = () => {
        setAssignPointDialog(true);
    };

    // 關閉發放點數對話框
    const closeAssignPointDialog = () => {
        setAssignPointDialog(false);
        setNewPoints('');
    };

    // 確認發放點數
    const confirmAssignPoint = () => {
        const pointsToAdd = parseInt(newPoints);
        if (isNaN(pointsToAdd)) {
            alert("請輸入有效的數字");
            return;
        }

        // 準備批量更新的數據
        const userPoints = selectedMultiUsers.map(userId => ({
            userId: userId.toString(),
            points: pointsToAdd
        }));

        // 使用新的批量更新 API
        fetch(`${SERVER_BASE_URL}/api/batch-update-points/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                userPoints,
                ensureNonNegative: true  // 設置為 true 以啟用非負點數邏輯
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(updatedUserInfo => {
                closeAssignPointDialog();
                // 重置選擇的用戶
                setSelectedMultiUsers([]);
                setIsAssignButtonEnabled(false);

                // 更新 Redux store
                dispatch({ type: 'SET_USER_INFO', payload: updatedUserInfo });

                // 目前時間
                const now = new Date();
                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要 +1
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');
                const seconds = now.getSeconds().toString().padStart(2, '0');
                const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
                // End

                userPoints.forEach(user => {
                    const params = {
                        emoji: '🙌',
                        eventTitle: `收到 ${parseInt(pointsToAdd)} 點`,
                        currentTime: `於 ${formattedTime} 獲得`,
                        eventDescription: `恭喜您已成功獲得 ${parseInt(pointsToAdd)}點`,
                        eventPrice: `快到會員系統確認，並至「優惠兌換」頁面查看/兌換最新的優惠!`,
                        // eventTakeTime: `服務時間大約 ${takeTime}分鐘`,
                        // eventRemark: `此服務注意事項: ${remark}`,
                        // eventCommentToStore: `給店家的備註: ${comment}`
                    };
                    const pushFlex = generateJson(params);

                    const encodedChannelAccessToken = encodeURIComponent(channelAccessToken);
                    const encodedUserId = encodeURIComponent(user.userId);

                    fetch(`${SERVER_BASE_URL}/api/push-line-flex-message/${encodedChannelAccessToken}/${encodedUserId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            messages: pushFlex,
                        }),
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log('Message sent:', data);
                        })
                        .catch(error => {
                            console.error('Error sending message:', error);
                        });

                    console.log(user.userId);
                })


                toast.success(`已成功為 ${selectedMultiUsers.length} 位用戶各發放 ${pointsToAdd} 點`, {
                    duration: 3000
                });
            })
            .catch(error => {
                console.error('Error updating points:', error);
                toast.error('發放點數時發生錯誤');
            });
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Container className='flex items-center flex-row'
                style={{ marginTop: "30px", marginLeft: "30px", marginBottom: '20px' }}>
                <div className="basis-1/4 mr-5">
                    <Autocomplete
                        freeSolo
                        id="client"
                        disableClearable
                        options={Array.isArray(userInfo) ? userInfo.map((user) => `${user.displayName} - ${user.phone}`) : []}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="依電話或名稱尋找"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    style: { borderRadius: '30px', width: '20vw' }
                                }}
                            />
                        )}
                        onInputChange={handleInputChange}
                        onChange={handleAutocompleteChange}
                    />
                </div>
                <div className="basis-1/6">
                    {/* 新增: 發放點數按鈕 */}
                    <Button
                        onClick={openAssignPointDialog}
                        disabled={!isAssignButtonEnabled}
                        style={{
                            backgroundColor: isAssignButtonEnabled ? 'rgba(44, 35, 81, 0.8)' : 'gray',
                            border: 'none'
                        }}
                    >
                        發放點數
                    </Button>
                </div>
            </Container>

            <Container className='d-flex flex-column'
                style={{ marginTop: "30px", marginLeft: "30px", paddingBottom: '50px' }}>

                <div style={{ height: '80vh', width: '70vw' }}>
                    <DataGrid
                        rows={Array.isArray(userInfo) ? (selectedUser ? userInfo.filter(user => user.userId === selectedUser) : userInfo) : []}
                        columns={columns}
                        style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', cursor: 'pointer' }}
                        onRowClick={(rowData) => handleRowClick(rowData.row)}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30, 50, 100, 200, 500]}
                        getRowId={(row) => row.userId}
                        rowSelectionModel={selectedMultiUsers}
                        checkboxSelection // 啟用多選
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedMultiUsers(newSelection);
                            setIsAssignButtonEnabled(newSelection.length > 0);
                        }}
                    />
                </div>
            </Container>

            {/* 新增: 發放點數對話框 */}
            <Dialog open={assignPointDialog} onClose={closeAssignPointDialog}>
                <DialogTitle
                    style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>發放點數</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="points"
                        label="要發放幾點?"
                        type="number"
                        fullWidth
                        name="points"
                        onChange={(e) => setNewPoints(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAssignPointDialog} style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#2C2351',
                        fontFamily: 'Fraunces, Arial, sans-serif'
                    }}>取消</Button>
                    <Button onClick={confirmAssignPoint} style={{
                        backgroundColor: 'rgba(44, 35, 81, 0.8)',
                        border: 'none',
                        color: 'white',
                        fontFamily: 'Fraunces, Arial, sans-serif'
                    }}>確定發放</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default AllClients;
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const SelfStoreMemberAppointment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const member_id = searchParams.get('id');
    const member_username = searchParams.get('username');

    const userInfo = useSelector(state => state.userInfo);
    const appointmentHistory = useSelector(state => state.appointmentHistory);
    const selfAppointmentsHistory = appointmentHistory.filter(appointment => appointment.partner === member_id);

    const rows = selfAppointmentsHistory.map(appointment => {
        const user = userInfo.find(user => user.userId === appointment.userId) || {};
        return {
            id: appointment.id,
            displayName: user.displayName || 'admin',
            userPicture: user.pictureUrl || '',
            title: appointment.title,
            currentTime: appointment.currentTime,
            price: appointment.price,
            takeTime: appointment.takeTime,
            bookDate: appointment.bookDate,
            bookTime: appointment.bookTime,
            status: appointment.status || '-',
            remark: appointment.remark,
            commentToStore: appointment.commentToStore,
            partner: member_username
        };
    });

    const columns = [
        { field: 'id', headerName: '訂單ID', width: 150 },
        {
            field: 'displayName',
            headerName: '預約人',
            width: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={params.row.userPicture} alt={params.value} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                    {params.value}
                </div>
            )
        },
        { field: 'partner', headerName: '服務人員', width: 150 },
        { field: 'title', headerName: '預約項目', width: 150 },
        { field: 'currentTime', headerName: '下單日', width: 200 },
        { field: 'bookDate', headerName: '預約日期', width: 200 },
        { field: 'bookTime', headerName: '預約時段', width: 200 },
        { field: 'status', headerName: '訂單狀態', width: 150 },
        { field: 'commentToStore', headerName: '給店家之備註', width: 300 },
    ];

    const addAppointmentManual = () => {
        navigate('/add-appointment-manual');
    }

    const handleRowClick = (navbar) => {
        navigate("/self-appointment-history-detail", {
            state: { navbar }
        });
    };

    return (
        <>
            <Container style={{ marginTop: "30px" }}>
                <Button className="py-2 rounded-pill" variant="primary" onClick={addAppointmentManual} style={{ width: '30%', backgroundColor: 'rgba(44, 35, 81, 0.8)', borderColor: 'transparent' }}><span style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>手動增加預約</span></Button>
            </Container>

            <Container style={{ marginTop: "30px", paddingBottom: '50px' }}>
                <div style={{ height: '80vh', width: '70vw' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', cursor: 'pointer' }}
                        onRowClick={(rowData) => handleRowClick(rowData.row)}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        getRowId={(row) => row.id}
                    // checkboxSelection
                    />
                </div>
            </Container>
        </>
    );
}

export default SelfStoreMemberAppointment;
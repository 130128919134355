import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Container } from 'react-bootstrap';

const ListAllCouponHistory = () => {
    const userInfo = useSelector(state => state.userInfo);
    const couponHistory = useSelector(state => state.couponHistory);

    const rows = couponHistory.map(coupon => {
        const user = userInfo.find(user => user.userId === coupon.userId) || {};
        return {
            id: coupon.id,
            userName: user.displayName || '未知使用者',
            userPicture: user.pictureUrl || '',
            title: coupon.title,
            currentTime: coupon.currentTime,
            pointCost: coupon.pointCost
        };
    });

    const columns = [
        { field: 'id', headerName: '訂單ID', width: 150 },
        {
            field: 'userName',
            headerName: '兌換人',
            width: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={params.row.userPicture} alt={params.value} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                    {params.value}
                </div>
            )
        },
        { field: 'currentTime', headerName: '兌換日', width: 200 },
        { field: 'title', headerName: '兌換項目', width: 150 },
        { field: 'pointCost', headerName: '花費點數', width: 300 },
    ];


    return (
        <Container style={{ marginTop: "30px", paddingBottom: '50px' }}>
            <div style={{ height: '80vh', width: '70vw' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', cursor: 'pointer' }}
                    // onRowClick={(rowData) => handleRowClick(rowData.row)}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20]}
                    getRowId={(row) => row.id}
                // checkboxSelection
                />
            </div>
        </Container>
    );
}

export default ListAllCouponHistory;
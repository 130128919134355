import { useState } from "react";
import { Container, Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { RxDividerHorizontal } from "react-icons/rx";
import { IoMdAdd } from "react-icons/io";
import toast from 'react-hot-toast';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const EditCouponItem = () => {
    const dispatch = useDispatch();
    const storeDB = useSelector(state => state.storeDB);

    const [isLoading, setIsLoading] = useState(false);
    const couponList = useSelector(state => state.couponList);
    const [open, setOpen] = useState(false);
    const [newCouponItem, setNewCouponItem] = useState({ title: '', points: '' });


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCouponItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddItem = () => {
        setIsLoading(true);
        fetch(`${SERVER_BASE_URL}/api/add-coupon-item/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "title": newCouponItem.title,
                "points": newCouponItem.points,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'SET_COUPON_LIST', payload: data });
                toast.success('新增成功', {
                    duration: 5000,
                });
            })
        setIsLoading(false);
        handleClose();
    }

    const removeItem = (item) => {
        setIsLoading(true);
        const confirmed = window.confirm(`確定要刪除「${item.title}」 嗎？`);
        const deleted_title = encodeURIComponent(item.title)

        if (confirmed) {
            fetch(`${SERVER_BASE_URL}/api/remove-coupon-item/${storeDB}/${deleted_title}`, {
                method: "DELETE",
            })
                .then(res => res.json())
                .then(data => {
                    dispatch({ type: 'SET_COUPON_LIST', payload: data });
                    toast.success('刪除成功', {
                        duration: 5000,
                    });
                })
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            <Container className='d-flex flex-column' style={{ marginTop: "30px", marginBottom: '20px' }}>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>所有可提供給客戶兌換的優惠</span>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '12px' }}><i>此頁內容會顯示於您客戶手機端的「優惠兌換」頁面</i></span>
            </Container>

            {
                couponList.length === 0 ?
                    (
                        <Container className='d-flex flex-column' style={{ marginTop: "30px", marginBottom: '10px' }}>
                            <p style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px' }}><i>目前沒有提供任何可兌換之優惠</i></p>
                        </Container>
                    )
                    :
                    (
                        <Container className='d-flex flex-column'>
                            {couponList.map((coupon) => (
                                <Box
                                    key={coupon.title}
                                    sx={{
                                        borderColor: '#2C2351',
                                        borderWidth: '0.6px',
                                        borderStyle: 'solid',
                                        backgroundColor: 'transparent',
                                        width: '60vw',
                                        height: '10vh',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0 10px',
                                        marginBottom: '10px', // 增加間距
                                    }}
                                >
                                    {/* <span style={{ color: '#0D7A7B', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', margin: '0' }}>{pointMethod.title}</span> */}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '16px', margin: '0' }}>{coupon.title}</span>
                                        <span style={{ color: 'rgba(44, 35, 81, 0.8)', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>扣除點數: {coupon.points}點</span>
                                    </div>
                                    <RxDividerHorizontal as={Button} onClick={() => removeItem(coupon)} style={{ marginRight: '10px', cursor: 'pointer' }} />
                                </Box>
                            ))}
                        </Container>

                    )
            }
            <Container className='d-flex flex-column' style={{ paddingBottom: '20vh' }}>
                <Box as={Button} onClick={handleOpen} sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    "&:hover": {
                        backgroundColor: 'rgba(44, 35, 81, 0.1)', // 改变按钮的 hover 背景色
                    },
                    width: '60vw',
                    height: '10vh',
                    padding: '0 10px',
                    marginBottom: '10px', // 增加間距
                }}>
                    <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', marginRight: '5px' }}>點我新增優惠兌換項目</span>
                    <IoMdAdd color='#2C2351' />
                </Box>
            </Container>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增優惠兌換項目</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="優惠標題"
                        type="text"
                        fullWidth
                        name="title"
                        // value={newPointMethod.title}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="points"
                        label="花費點數"
                        type="number"
                        fullWidth
                        name="points"
                        // value={newPointMethod.content}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消</Button>
                    <Button onClick={handleAddItem} style={{ backgroundColor: 'rgba(44, 35, 81, 0.8)', color: 'white', fontFamily: 'Fraunces, Arial, sans-serif' }}>新增</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default EditCouponItem;
import { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Spinner from 'react-bootstrap/Spinner';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import crypto from 'crypto-js';
import toast from 'react-hot-toast';
import { nanoid } from 'nanoid';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const DisableBookTime = () => {
    const storeDB = useSelector(state => state.storeDB);
    const appointmentHistory = useSelector(state => state.appointmentHistory);
    const [openCloseDateTime, setOpenCloseDateTime] = useState([]);

    const [fetchFromDB, setFetchFromDB] = useState(false);
    const [serviceTimeInterval, setServiceTimeInterval] = useState('');
    const [openServiceTime, setOpenServiceTime] = useState('');
    const [closeServiceTime, setCloseServiceTime] = useState('');
    const [holiday, setHoliday] = useState('');

    const [availableTimes, setAvailableTimes] = useState([]);
    const [closeTimes, setCloseTimes] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');

    const shouldDisableDate = (date) => {
        const today = new Date();
        return date.isBefore(today, 'day');
    };

    // 一次先產生所有時段
    // 從「開始接受預約時間」到「最後可接受預約時間」的所有時間段（用 serviceTimeInterval 來分割時間段）
    // 例如每30分鐘切一個時間段
    const generateTimeArray = (startTime, endTime, interval) => {
        const result = [];
        let currentTime = dayjs(startTime, 'HH:mm');

        while (currentTime.isBefore(dayjs(endTime, 'HH:mm'))) {
            result.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }

        result.push(dayjs(endTime, 'HH:mm').format('HH:mm')); // 添加最后一个时间
        return result;
    };

    const handleDateChange = (date) => {
        date = date.format('YYYY-MM-DD');
        setSelectedDate(date);

        const timeArray = generateTimeArray(openServiceTime, closeServiceTime, serviceTimeInterval); // 先產生所有時段
        const appointmentsOnDate = appointmentHistory.filter(appointment => appointment.bookDate === date); // 找到今天日期的所有預約        

        const otherOpenCloseDateTime = openCloseDateTime.filter(datetime => datetime.bookDate === date);


        let forceCloseDateTime = [];
        let forceOpenDateTime = [];
        for (let i = 0; i < otherOpenCloseDateTime.length; i++) {
            if (otherOpenCloseDateTime[i].action === 'close' && otherOpenCloseDateTime[i].booked === 'false') {
                forceCloseDateTime.push(otherOpenCloseDateTime[i].bookTime);
            }
            else if (otherOpenCloseDateTime[i].action === 'open' && otherOpenCloseDateTime[i].booked === 'false') {
                forceOpenDateTime.push(otherOpenCloseDateTime[i].bookTime);
            }
        }

        // 得到「服務所佔據」的時間
        // 例如當日 10:30 有 A 服務，且 A 服務需要花 90分鐘
        // 則 bookedAlreadyTime = ["10:30", "11:00", "11:30"] 
        // "12:00" 開始就可以預約，因為 A 服務剛好做到 "12:00"
        const bookedAlreadyTime = appointmentsOnDate.flatMap(appointment => {
            const start = dayjs(appointment.bookTime, 'HH:mm');
            const end = start.add(appointment.takeTime, 'minute');
            const result = [];

            let currentTime = start;
            while (currentTime.isBefore(end)) {
                result.push(currentTime.format('HH:mm'));
                currentTime = currentTime.add(serviceTimeInterval, 'minute');
            }

            return result;
        });

        const availableTime = timeArray.filter(time => !bookedAlreadyTime.includes(time)); // 將「服務所佔據的時間」從「所有時段」中移除
        console.log("availableTime: ", availableTime);
        const filteredAvailableTime = availableTime.filter(time => !forceCloseDateTime.includes(time)); // 將「被強制關閉的時間」從「所有可預約時段」中移除
        console.log("filteredAvailableTime: ", filteredAvailableTime);
        forceOpenDateTime.forEach(time => { // 把「被強制打開的時間」push 進「所有可預約時段」
            if (!filteredAvailableTime.includes(time)) {
                filteredAvailableTime.push(time);
            }
        });

        const filteredBookedAlreadyTime = bookedAlreadyTime.filter(time => !forceOpenDateTime.includes(time));
        console.log("filteredBookedAlreadyTime: ", filteredBookedAlreadyTime);
        forceCloseDateTime.forEach(time => {
            filteredBookedAlreadyTime.push(time);
        });

        setAvailableTimes(filteredAvailableTime.sort());
        setCloseTimes(filteredBookedAlreadyTime.sort());

    };

    const checkTimeWhetherInDB = async (time) => {
        const response = await fetch(`${SERVER_BASE_URL}/api/get-all-force-open-close-date-time/${storeDB}`);
        const data = await response.json();
        const record = data.find(record => record.bookDate === selectedDate && record.bookTime === time);
        return record ? record : null;
    }


    const changeAction = (id, action) => {
        fetch(`${SERVER_BASE_URL}/api/change-force-time-action/${storeDB}/${id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "action": action,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log("[SUCESS] function: changeAction");
            })
    }

    const addForceTimeRecord = (time, action) => {
        fetch(`${SERVER_BASE_URL}/api/add-force-date-time/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "id": nanoid(),
                "user": "admin",
                "bookDate": selectedDate,
                "bookTime": time,
                "action": action,
                "booked": "false"
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log("[SUCESS] function: addForceTimeRecord");
            })
    }

    // 先找DB，是否相同日期與時間已經有紀錄
    // 若有：改action
    // 若無：新增一筆記錄
    const closeAnTime = async (time) => {
        setFetchFromDB(true);
        const recordId = await checkTimeWhetherInDB(time);
        if (recordId) {
            // 把action改成close
            changeAction(recordId, 'close')
        } else {
            // 新增一筆記錄
            addForceTimeRecord(time, "close")
        }

        // 將「要被關閉的時間」從 availableTimes 挑出來
        // 再將這個時間加到 closeTimes
        const updatedAvailableTimes = availableTimes.filter(available => available !== time);
        setAvailableTimes(updatedAvailableTimes.sort());
        setCloseTimes(prevCloseTimes => [...prevCloseTimes, time].sort());
        toast.success("更改成功", {
            duration: 3000
        });
        setFetchFromDB(false);
    };

    const openAnTime = async (time) => {
        setFetchFromDB(true);
        const recordId = await checkTimeWhetherInDB(time);
        if (recordId) {
            // 把action改成close
            changeAction(recordId, 'open')
        } else {
            // 新增一筆記錄
            addForceTimeRecord(time, "open")
        }

        // 將「要被開放的時間」從 closeTimes 挑出來
        // 再將這個時間加到 availableTimes
        const updatedCloseTimes = closeTimes.filter(close => close !== time);
        setCloseTimes(updatedCloseTimes.sort());
        setAvailableTimes(prevAvailableTimes => [...prevAvailableTimes, time].sort());
        toast.success("更改成功", {
            duration: 3000
        });
        setFetchFromDB(false);
    }

    const closeAllTime = async () => {
        setFetchFromDB(true);
        /*
        先拿到目前所有 availableTimes
        再一筆一筆去看是否出現在 DB 中，

        *「若沒有」
        1. 將這些「時段」push 到一個 array 中
        2.將這個 array 的元素都 POST 到 DB

        *「若有」
        1. 將這些「時段」push 到一個 array 中（需要有 Id）
        將它的 action 改成 close
        */
        const timesToAdd = [];
        const timesToUpdate = [];

        for (const time of availableTimes) {
            const record = await checkTimeWhetherInDB(time);
            if (record === null) {
                timesToAdd.push(time);
            } else {
                timesToUpdate.push({ id: record.id, bookTime: time });
            }
        }

        for (let i = 0; i < timesToUpdate.length; i++) {
            changeAction(timesToUpdate[i].id, "close");
        }
        for (let i = 0; i < timesToAdd.length; i++) {
            addForceTimeRecord(timesToAdd[i], "close");
        }

        setCloseTimes(prevCloseTimes => [...prevCloseTimes, ...availableTimes].sort());
        setAvailableTimes([]);
        toast.success("更改成功", {
            duration: 3000
        });
        setFetchFromDB(false);
    }

    const openAllTime = async () => {
        setFetchFromDB(true);
        /*
        先拿到目前所有 closeTimes
        再一筆一筆去看是否出現在 DB 中，

        *「若沒有」
        1. 將這些「時段」push 到一個 array 中
        2. 將這個 array 的元素都 POST 到 DB

        *「若有」
        1. 將這些「時段」push 到一個 array 中（需要有 Id）
        將它的 action 改成 open
        */
        const timesToAdd = [];
        const timesToUpdate = [];

        for (const time of closeTimes) {
            const record = await checkTimeWhetherInDB(time);
            if (record === null) {
                timesToAdd.push(time);
            } else {
                timesToUpdate.push({ id: record.id, bookTime: time });
            }
        }

        for (let i = 0; i < timesToUpdate.length; i++) {
            changeAction(timesToUpdate[i].id, "open");
        }
        for (let i = 0; i < timesToAdd.length; i++) {
            addForceTimeRecord(timesToAdd[i], "open");
        }

        // setCloseTimes(prevCloseTimes => [...prevCloseTimes, ...availableTimes].sort());
        setCloseTimes([]);
        setAvailableTimes(prevAvailableTimes => [...prevAvailableTimes, ...closeTimes].sort());
        toast.success("更改成功", {
            duration: 3000
        });

        setFetchFromDB(false);
    }


    useEffect(() => {
        setFetchFromDB(true);
        fetch(`${SERVER_BASE_URL}/api/get-store-setting/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                // dispatch({ type: 'SET_STORE_SETTING', payload: data[0] });
                setServiceTimeInterval(data[0].serviceTimeInterval);
                setOpenServiceTime(data[0].openServiceTime);
                setCloseServiceTime(data[0].closeServiceTime);
                setHoliday(data[0].holiday);
            })
        setFetchFromDB(false);
    }, []);

    useEffect(() => {
        setFetchFromDB(true);
        fetch(`${SERVER_BASE_URL}/api/get-all-force-open-close-date-time/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                setOpenCloseDateTime(data);
            })
        setFetchFromDB(false);
    }, [selectedDate]);

    return (
        <>
            {
                fetchFromDB &&
                (
                    <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
            }
            <Container className='d-flex flex-column' style={{ marginTop: "30px", marginLeft: "30px" }}>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>1. 選擇日期: 要調整哪一天的時段</span>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>2. 開放或關閉時段: 點選時段已開放或關閉時段</span>
                <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '14px' }}><i>若某時段「已被預約」或「目前開放中」，經由您的評估後可再將此時段重新開放或關閉</i></span>
            </Container>

            <Container style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '50%', marginTop: "20px", marginLeft: "30px", marginBottom: '20px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            label="要改哪天的預約時段"
                            onChange={handleDateChange}
                            shouldDisableDate={shouldDisableDate}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Container>

            {
                availableTimes.length > 0 ? (
                    <Container style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", }}>
                        <div style={{ paddingBottom: '20px', display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%' }}>
                                <span style={{ color: '#2C2351', fontWeight: 'bold' }}>空閒時段: </span>
                                <span style={{ color: '#2C2351', 'fontSize': '14px' }}><i>點選時段可將時段關閉，用戶將無法預約該時段</i></span>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '10px' }}>
                                {availableTimes.map((time, index) => (
                                    <Button key={index} variant="primary" onClick={() => { closeAnTime(time) }} style={{ width: 'calc(25% - 10px)', marginRight: '10px', marginBottom: '10px', fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'white', borderColor: '#2C2351', color: '#2C2351' }}>{time}</Button>
                                ))}
                                <Button variant="primary" onClick={closeAllTime} style={{ width: 'calc(25% - 10px)', marginRight: '10px', marginBottom: '10px', fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>全部關閉</Button>
                            </div>
                        </div>
                    </Container>
                )
                    :
                    (
                        <Container style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", }}>
                            <div style={{ paddingBottom: '20px', display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ width: '100%' }}>
                                    <span style={{ color: '#2C2351', fontWeight: 'bold' }}>空閒時段: </span>
                                    <span style={{ color: '#2C2351', 'fontSize': '14px' }}><i>點選時段可將時段關閉，用戶將無法預約該時段</i></span>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '10px' }}>
                                    <i>無任何空閒時段</i>
                                </div>
                            </div>
                        </Container>
                    )
            }

            {
                closeTimes.length > 0 ? (
                    <Container style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", }}>
                        <div style={{ paddingBottom: '20px', display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%' }}>
                                <span style={{ color: '#2C2351', fontWeight: 'bold' }}>已被預約或處於服務中之時段: </span>
                                <span style={{ color: '#2C2351', 'fontSize': '14px' }}><i>點選時段可將時段開放，用戶將可以預約該時段</i></span>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '10px' }}>
                                {closeTimes.map((time, index) => (
                                    <Button key={index} variant="primary" onClick={() => { openAnTime(time) }} style={{ width: 'calc(25% - 10px)', marginRight: '10px', marginBottom: '10px', fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'white', borderColor: '#2C2351', color: '#2C2351' }}>{time}</Button>
                                ))}
                                <Button variant="primary" onClick={openAllTime} style={{ width: 'calc(25% - 10px)', marginRight: '10px', marginBottom: '10px', fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}>全部開放</Button>
                            </div>
                        </div>
                    </Container>
                )
                    :
                    (
                        <Container style={{ fontFamily: 'Fraunces, Arial, sans-serif', width: '80%', marginTop: "50px", marginLeft: "30px", }}>
                            <div style={{ paddingBottom: '20px', display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ width: '100%' }}>
                                    <span style={{ color: '#2C2351', fontWeight: 'bold' }}>已被預約或處於服務中之時段: </span>
                                    <span style={{ color: '#2C2351', 'fontSize': '14px' }}><i>點選時段可將時段開放，用戶將可以預約該時段</i></span>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '10px' }}>
                                    <i>無任何預約時段</i>
                                </div>
                            </div>
                        </Container>
                    )
            }
        </>

    );
}

export default DisableBookTime;
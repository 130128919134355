import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const Schedule = () => {
    const navigate = useNavigate();
    const storePartners = useSelector(state => state.storePartners);
    const userInfo = useSelector(state => state.userInfo);
    const appointmentHistory = useSelector(state => state.appointmentHistory); // 這裡的info只有userId沒有displayName

    // 將 appointmentHistory 新增 displyName 欄位，並將所有內容放在一個新的 List 
    const appointmentHistoryList = appointmentHistory.map(appointment => {
        // if (appointment.userId === "admin") {
        //     return null; // 略過 userId 為 "admin" 的資料
        // }

        const matchingUser = userInfo.find(user => user.userId === appointment.userId);
        return {
            ...appointment, // 保留 appointmentHistory 中原有的欄位
            displayName: matchingUser ? matchingUser.displayName : 'admin' // 如果找到對應的使用者，取得其 displayName，否則為空字串
        };
    }).filter(appointment => appointment !== null); // 過濾掉為 null 的資料;


    const getPartnerColor = (partnerId) => {
        const partner = storePartners.find(partner => partner.id === partnerId);
        return partner ? partner.color : '#000'; // 默认颜色为黑色
    };

    const tarnslatePartnerIdToUsername = (event) => {
        const partner = storePartners.find(partner => partner.id === event.partner);
        const partnerUsername = partner ? partner.username : 'Unknown';
        return partnerUsername;
    };

    const convertedEvents = appointmentHistoryList
        .filter(event => event.status !== "cancel")
        .map(event => ({
            title: event.bookTime + ' ' + event.title,
            start: new Date(event.bookDate + 'T' + event.bookTime), // 使用字符串拼接日期和时间
            // end: new Date(event.bookDate + 'T' + event.bookTime), // 这里的 end 也可以根据需要设置
            end: new Date(new Date(event.bookDate + 'T' + event.bookTime).getTime() + event.takeTime * 60 * 1000), // 加上 takeTime 分钟的时间
            color: getPartnerColor(event.partner),
            additionalInfo: {
                // 额外信息
                displayName: event.displayName,
                commentToStore: event.commentToStore,
                bookDate: event.bookDate,
                bookTime: event.bookTime,
                currentTime: event.currentTime,
                id: event.id,
                price: event.price,
                remark: event.remark,
                takeTime: event.takeTime,
                userId: event.userId,
                title: event.title,
                partner: event.partner,
                status: event.status
            },
            tooltip: tarnslatePartnerIdToUsername(event) + "的訂單\n" + "\n預約時間: " + event.bookTime + "\n預約人: " + event.displayName + "\n預約項目: " + event.title + "\n給店家的備註: " + event.commentToStore,
        }));

    const handleEventClick = (event) => {
        console.log('Clicked event:', event);
        // 在這裡可以執行你想要的操作，比如彈出提示框、顯示詳細資訊等
        const navbar = {
            "bookDate": event.additionalInfo["bookDate"],
            "bookTime": event.additionalInfo["bookTime"],
            "commentToStore": event.additionalInfo["commentToStore"],
            "currentTime": event.additionalInfo["currentTime"],
            "displayName": event.additionalInfo["displayName"],
            "id": event.additionalInfo["id"],
            "price": event.additionalInfo["price"],
            "remark": event.additionalInfo["remark"],
            "takeTime": event.additionalInfo["takeTime"],
            "title": event.additionalInfo["title"],
            "userId": event.additionalInfo["userId"],
            "partner": event.additionalInfo["partner"],
            "status": event.additionalInfo["status"],
        }
        navigate("/self-appointment-history-detail", {
            state: { navbar }
        });
    };

    return (
        <div style={{ height: '80vh' }}>
            <Calendar
                localizer={localizer}
                events={convertedEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ margin: '50px', fontFamily: 'Fraunces, Arial, sans-serif' }}
                messages={{
                    showMore: (total) => (<span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}><b> +{total} 更多</b></span>)
                }}
                onSelectEvent={handleEventClick}
                tooltipAccessor={(event) => event.tooltip}
                eventPropGetter={(event) => {
                    const backgroundColor = event.color || 'blue';
                    return { style: { backgroundColor } };
                }}
            // components={{
            //     event: EventComponent,
            // }}
            />
        </div>
    );
}

export default Schedule;